import moment from 'moment-timezone'
import Constants from '@/utils/Constants'

export default {
  // *********************
  // UserRole
  // *********************
  /**
   * ユーザーロール：整数からビット値に変換
   */
  roleToBit (userRole) {
    let bit = ('000000' + userRole.toString(2)).slice(-7).split('')
    return bit.reverse().join('')
    // return ('00000' + userRole.toString(2)).slice(-6)
  },
  /**
   * ユーザーロール：ビット値から整数に変換
   */
  roleToInt (userRole) {
    let n = 0
    if (typeof userRole !== 'string' || userRole.length !== 7) return n
    if (userRole.charAt(0) === '1') n += 64
    if (userRole.charAt(1) === '1') n += 32
    if (userRole.charAt(2) === '1') n += 16
    if (userRole.charAt(3) === '1') n += 8
    if (userRole.charAt(4) === '1') n += 4
    if (userRole.charAt(5) === '1') n += 2
    if (userRole.charAt(5) === '1') n += 1
    return n
  },
  /**
   * ユーザーロール：整数からロールコードを取得
   */
  getRoleCodeByInt (userRole) {
    let bit = this.roleToBit(userRole)
    let bitArray = []
    for(let i = 0, l = bit.length; i < l; i++) {
      if (bit.charAt(i) === '1') {
        bitArray.push(Constants.UserRole[i].code)
      }
    }
    return bitArray.join('、')
  },
  /**
   * ユーザーロール：整数からロールラベルを取得
   */
  getRoleLabelByInt (userRole) {
    let bit = this.roleToBit(userRole)
    let bitArray = []
    for(let i = 0, l = bit.length; i < l; i++) {
      if (bit.charAt(i) === '1') {
        bitArray.push(Constants.UserRole[i].label)
      }
    }
    return bitArray.join('、')
  },

  // *********************
  // Date
  // *********************
  /**
   * GMTをJSTに変換して指定のフォーマットで返す
   * @param  format  YYYY/MM/DD HH:mm:ss
   */
  getJSTDate (value, format) {
    // return moment(value).tz('Asia/Tokyo').format(format)
    return moment(value).add(9, 'hours').format(format)
    // return moment(value).add(9, 'h').format(format)
  },
  getDate (value, format) {
    return moment(value).format(format)
  },

  // *********************
  // Object
  // *********************
  /**
   * keyでソート
   */
  sortByKey (key, ascending) {
    return (a, b) => {
      if (a[key] < b[key]) return ascending ? -1 : 1
      if (a[key] > b[key]) return ascending ? 1 : -1
      return 0
    }
  }
}
