export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Results: [
        {
          Result: 'success',
          AssetId: 101
        },
        {
          Result: 'success',
          AssetId: 102
        },
        {
          Result: 'error',
          AssetId: 103
        }
      ]
    }
  }
}