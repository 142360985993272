import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import Loading from '@/components/Loading'
import Constants from '@/utils/Constants'
import Utility from '@/utils/Utility'

export default {
  name: 'Moderator',
  components: {
    Loading
  },
  metaInfo () {
    this.setMeta({
      title: '点検者一覧 | ' + Constants.SiteInfo.title
    })
    return this.getMeta
  },
  data () {
    return {
      showLoading: false,
      registerHash: Constants.Params.RegisterHash,
      // テーブルオプション
      tableColumns: [
        'Name',
        'InspectorCompanyName',
        'Phone1',
        'UserRole',
        'CreatedAt',
      ],
      tableData: [],
      tableOptions: {
        headings: {
          Name: '氏名',
          InspectorCompanyName: '会社名',
          Phone1: '連絡先',
          UserRole: 'ロール',
          CreatedAt: '作成日',
        },
        columnsClasses: {
          Name: '',
          InspectorCompanyName: 'wp-20',
          Phone1: 'wp-20',
          UserRole: 'wp-20',
          CreatedAt: 'wp-15',
        },
        sortable: [
          'Name',
          'InspectorCompanyName',
          'Phone1',
          'UserRole',
          'CreatedAt'
        ],
        filterable: false,
        texts: Constants.TableOptions.getTexts('点検者'),
        perPage: Constants.TableOptions.perPage,
        perPageValues: Constants.TableOptions.perPageValues,
        pagination: Constants.TableOptions.pagination,
        resizableColumns: false,
      },
      // 絞込み
      showAll: 'true',
      role: null
    }
  },
  mounted () {
    // 初期化
    this.init()
  },
  computed: {
    ...mapState({
      userModel: state => state.inspector.userModel,
      companyQuery: state => state.inspector.companyQuery,
      nameQuery: state => state.inspector.nameQuery,
    }),
    ...mapGetters({
      getMeta: 'meta/get',
      isSystemAdmin: 'auth/isSystemAdmin',
      isTenantAdmin: 'auth/isTenantAdmin'
    }),
    company: {
      get () { return this.companyQuery },
      set (value) { this.setCompanyQuery(value) }
    },
    name: {
      get () { return this.nameQuery },
      set (value) { this.setNameQuery(value) }
    },
    showResult () {
      return !this.showLoading && this.tableData.length > 0
    }
  },
  methods: {
    ...mapActions({
      setMeta: 'meta/set',
      loadUserList: 'inspector/loadUserList',
      showError: 'error/show'
    }),
    ...mapMutations({
      setCompanyQuery: 'inspector/setCompanyQuery',
      setNameQuery: 'inspector/setNameQuery'
    }),
    init () {
      this.search()
    },
    getViewData (props) {
      let key = props.column
      let value = props.row[key]

      if (key === 'UserRole') return Utility.getRoleLabelByInt(value)
      if (key === 'Phone1') return value + '-' + props.row.Phone2 + '-' + props.row.Phone3
      if (key === 'CreatedAt') return Utility.getJSTDate(value, 'YYYY/MM/DD HH:mm')
      return value
    },
    changePage () {
      // console.log(this.$refs.table.page)
    },
    search (type) {
      let parameters = {}
      if (this.showAll !== null) parameters.showAll = this.showAll === 'true'
      // if (type !== null) parameters.type = type
      parameters.type = type === undefined ? -1 : type

      // ユーザー一覧を読出し
      this.showLoading = true
      this.loadUserList(parameters)
        .then(() => {
          this.tableData = $.extend(this.userModel, [], true)
          this.showLoading = false
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
        })
    }
  }
}