import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Vue from 'vue'

const state = {
  targetId: Constants.Params.RegisterHash,
  values: {},
  copyMode: false
}

const getters = {
  get: state => state,
  isRegister: (state) => state.targetId === Constants.Params.RegisterHash,
  isModify: (state) => state.targetId !== Constants.Params.RegisterHash
}

const actions = {
  /**
   * 初期読出し
   */
  initLoad ({commit}, {targetId}) {
    commit('setTargetId', targetId)

    return new Promise((resolve, reject) => {
      if (getters.isRegister(state)) {
        resolve()

      } else {
        // お知らせ詳細を読出し
        ApiManager.request(
          ['notice'],
          ['v1NoticeDetailGet'],
          [{ NoticeId: targetId }])
          .then(res => {
            if (res[0].data.Result === Constants.ApiResult.Success) {
              commit('initLoaded', res[0].data.Notices[0])
              resolve()
              
            } else {
              reject(res[0].data)
            }
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  },
  copyData ({commit}, data) {
    commit('setTargetId', Constants.Params.RegisterHash)
    commit('setValues', data)
    commit('setCopyMode', true)
  }
}

const mutations = {
  initLoaded (state, data) {
    console.log(data)
    Vue.set(state, 'values', {
      subject: data.NoticeSubject,
      body: data.NoticeText,
      invalidFlag: !data.LiveFlg,
      note: data.Note
    })

    if (data.OpenTermStartDt) {
      let sd = new Date(data.OpenTermStartDt)
      // let sh = sd.getHours()
      // sd.setHours(sh + 9)
      Vue.set(state.values, 'startDate', sd)
      console.log(state.values.startDate)
    }
    if (data.OpenTermEndDt) {
      let ed = new Date(data.OpenTermEndDt)
      // let eh = ed.getHours()
      // ed.setHours(eh + 9)
      Vue.set(state.values, 'endDate', ed)
    }
  },
  setTargetId (state, targetId) {
    state.targetId = targetId
  },
  setValues (state, values) {
    Vue.set(state, 'values', values)
  },
  setCopyMode (state, flag) {
    state.copyMode = flag
  },
  reset (state) {
    // state.targetId = Constants.Params.RegisterHash
    Vue.set(state, 'values', {})
    state.copyMode = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
