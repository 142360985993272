import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

// views
import Signin from '@/views/Signin'
import NotFound from '@/views/NotFound'
// import Mypage from '@/views/Mypage'

// view : Register
import Register from '@/views/Register'
import RegisterInput from '@/views/Register/Input'
import RegisterConfirm from '@/views/Register/Confirm'
import RegisterComplete from '@/views/Register/Complete'

// view : Password
import Password from '@/views/Password'
import PasswordInput from '@/views/Password/Input'
import PasswordConfirm from '@/views/Password/Confirm'
import PasswordComplete from '@/views/Password/Complete'

// views : notice
import Notice from '@/views/Notice'
import NoticeForm from '@/views/Notice/Form'
import NoticeInput from '@/views/Notice/Form/Input'
import NoticeConfirm from '@/views/Notice/Form/Confirm'
import NoticeComplete from '@/views/Notice/Form/Complete'

// views : Tenant
import Tenant from '@/views/Tenant'
import TenantForm from '@/views/Tenant/Form'
import TenantInput from '@/views/Tenant/Form/Input'
import TenantConfirm from '@/views/Tenant/Form/Confirm'
import TenantComplete from '@/views/Tenant/Form/Complete'

// views : Asset
import Asset from '@/views/Asset'
import AssetForm from '@/views/Asset/Form'
import AssetInput from '@/views/Asset/Form/Input'
import AssetConfirm from '@/views/Asset/Form/Confirm'
import AssetComplete from '@/views/Asset/Form/Complete'
import AssetRead from '@/views/Asset/Read'

// views : tenantAdmin
import TenantAdmin from '@/views/TenantAdmin'
import TenantAdminForm from '@/views/TenantAdmin/Form'
import TenantAdminInput from '@/views/TenantAdmin/Form/Input'
import TenantAdminConfirm from '@/views/TenantAdmin/Form/Confirm'
import TenantAdminComplete from '@/views/TenantAdmin/Form/Complete'

// views : Moderator
import Moderator from '@/views/Moderator'
import ModeratorForm from '@/views/Moderator/Form'
import ModeratorInput from '@/views/Moderator/Form/Input'
import ModeratorConfirm from '@/views/Moderator/Form/Confirm'
import ModeratorComplete from '@/views/Moderator/Form/Complete'

// views : Inspector
import Inspector from '@/views/Inspector'
import InspectorForm from '@/views/Inspector/Form'
import InspectorInput from '@/views/Inspector/Form/Input'
import InspectorConfirm from '@/views/Inspector/Form/Confirm'
import InspectorComplete from '@/views/Inspector/Form/Complete'

// views : Account
import Account from '@/views/Account'
import AccountInput from '@/views/Account/Input'
import AccountConfirm from '@/views/Account/Confirm'
import AccountComplete from '@/views/Account/Complete'

// utils
import Constants from '@/utils/Constants'

Vue.use(Router)
Vue.use(Meta)

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // スラ止めの場合にリダイレクト
    // { path: '/event/register', redirect: '/event/register/form'},
    { path: '/account', redirect: '/account/form'},
    { path: '/register', redirect: '/register/form'},
    { path: '/password', redirect: '/password/form'},

    // ダッシュボード
    {
      path: '/',
      redirect: { name: 'Notice' }
      // name: 'Mypage',
      // component: Mypage,
      // meta: { requiresAuth: true }
    },

    // お知らせ管理
    {
      path: '/notice',
      name: 'Notice',
      component: Notice,
      meta: { requiresAuth: true }
    },
    {
      path: '/notice/:noticeId',
      component: NoticeForm,
      props: route => ({ noticeId: route.params.noticeId }),
      meta: { requiresAuth: true },
      children: [
        // 入力
        {
          path: 'form',
          name: 'NoticeForm',
          component: NoticeInput
        },
        // 確認
        {
          path: 'confirm',
          name: 'NoticeConfirm',
          component: NoticeConfirm
        },
        // 完了
        {
          path: 'complete',
          name: 'NoticeComplete',
          component: NoticeComplete
        }
      ]
    },

    // テナント管理
    {
      path: '/tenant',
      name: 'Tenant',
      component: Tenant,
      meta: { requiresAuth: true }
    },
    {
      path: '/tenant/:tenantIdentity',
      component: TenantForm,
      props: route => ({ tenantIdentity: route.params.tenantIdentity }),
      meta: { requiresAuth: true },
      children: [
        // 入力
        {
          path: 'form',
          name: 'TenantForm',
          component: TenantInput
        },
        // 確認
        {
          path: 'confirm',
          name: 'TenantConfirm',
          component: TenantConfirm
        },
        // 完了
        {
          path: 'complete',
          name: 'TenantComplete',
          component: TenantComplete
        }
      ]
    },

    // アセット管理
    {
      path: '/asset',
      name: 'Asset',
      component: Asset,
      meta: { requiresAuth: true }
    },
    {
      path: '/asset/read',
      name: 'AssetRead',
      component: AssetRead,
      meta: { requiresAuth: true }
    },
    {
      path: '/asset/:assetId',
      component: AssetForm,
      props: route => ({ assetId: route.params.assetId }),
      meta: { requiresAuth: true },
      children: [
        // 入力
        {
          path: 'form',
          name: 'AssetForm',
          component: AssetInput
        },
        // 確認
        {
          path: 'confirm',
          name: 'AssetConfirm',
          component: AssetConfirm
        },
        // 完了
        {
          path: 'complete',
          name: 'AssetComplete',
          component: AssetComplete
        }
      ]
    },

    // テナントアドミン管理
    {
      path: '/tenantAdmin',
      name: 'TenantAdmin',
      component: TenantAdmin,
      meta: { requiresAuth: true }
    },
    {
      path: '/tenantAdmin/:userIdentity',
      component: TenantAdminForm,
      props: route => ({ userIdentity: route.params.userIdentity }),
      meta: { requiresAuth: true },
      children: [
        // 入力
        {
          path: 'form',
          name: 'TenantAdminForm',
          component: TenantAdminInput
        },
        // 確認
        {
          path: 'confirm',
          name: 'TenantAdminConfirm',
          component: TenantAdminConfirm
        },
        // 完了
        {
          path: 'complete',
          name: 'TenantAdminComplete',
          component: TenantAdminComplete
        }
      ]
    },

    // 管理者管理
    {
      path: '/moderator',
      name: 'Moderator',
      component: Moderator,
      meta: { requiresAuth: true }
    },
    {
      path: '/moderator/:userIdentity',
      component: ModeratorForm,
      props: route => ({ userIdentity: route.params.userIdentity }),
      meta: { requiresAuth: true },
      children: [
        // 入力
        {
          path: 'form',
          name: 'ModeratorForm',
          component: ModeratorInput
        },
        // 確認
        {
          path: 'confirm',
          name: 'ModeratorConfirm',
          component: ModeratorConfirm
        },
        // 完了
        {
          path: 'complete',
          name: 'ModeratorComplete',
          component: ModeratorComplete
        }
      ]
    },

    // 点検者管理
    {
      path: '/inspector',
      name: 'Inspector',
      component: Inspector,
      meta: { requiresAuth: true }
    },
    {
      path: '/inspector/:userIdentity',
      component: InspectorForm,
      props: route => ({ userIdentity: route.params.userIdentity }),
      meta: { requiresAuth: true },
      children: [
        // 入力
        {
          path: 'form',
          name: 'InspectorForm',
          component: InspectorInput
        },
        // 確認
        {
          path: 'confirm',
          name: 'InspectorConfirm',
          component: InspectorConfirm
        },
        // 完了
        {
          path: 'complete',
          name: 'InspectorComplete',
          component: InspectorComplete
        }
      ]
    },

    // プロフィール編集
    {
      path: '/account',
      component: Account,
      meta: { requiresAuth: true },
      children: [
        // 入力
        {
          path: 'form',
          name: 'Account',
          component: AccountInput
        },
        // 確認
        {
          path: 'confirm',
          name: 'AccountConfirm',
          component: AccountConfirm
        },
        // 完了
        {
          path: 'complete',
          name: 'AccountComplete',
          component: AccountComplete
        }
      ]
    },

    // ログイン
    {
      path: '/signin',
      name: 'Signin',
      component: Signin
    },

    // パスワード登録（新規ユーザー）
    {
      path: '/register',
      component: Register,
      children: [
        // 入力
        {
          path: 'form',
          name: 'Register',
          component: RegisterInput
        },
        // 確認
        {
          path: 'confirm',
          name: 'RegisterConfirm',
          component: RegisterConfirm
        },
        // 完了
        {
          path: 'complete',
          name: 'RegisterComplete',
          component: RegisterComplete
        }
      ]
    },
    // パスワード変更（既存ユーザー）
    {
      path: '/password',
      component: Password,
      children: [
        // 入力
        {
          path: 'form',
          name: 'Password',
          component: PasswordInput
        },
        // 確認
        {
          path: 'confirm',
          name: 'PasswordConfirm',
          component: PasswordConfirm
        },
        // 完了
        {
          path: 'complete',
          name: 'PasswordComplete',
          component: PasswordComplete
        }
      ]
    },
    
    // 404
    {
      path: '/404',
      name: 'NotFound',
      component: NotFound
    },
    {
      path: '*',
      redirect: 'Signin'
    }
  ]
})

/* 認証していない場合にリダイレクト */
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (requiresAuth) {
    // ローカルストレージにユーザー情報が保持されているかチェック
    // 保持されていなければログインページにリダイレクト
    Constants.checkAuth()
      .then(routeName => {
        if (routeName) {
          next({name: routeName})
        } else {
          next()
        }
      })
      .catch(() => {
        next({name: 'Signin'})
      })
  } else {
    next() // next() を常に呼び出すようにしてください!
  }
})

export default router
