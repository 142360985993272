import ApiManager from '@/utils/ApiManager'
import Vue from 'vue'

const state = {
  map: null,
  // MapVIewのモデル
  model: [],
  // グローバルナビゲーション表示をしているか判別
  showGnav: false,
  // 生成されたMarkerのリスト
  markerList: [],
  // 選択されたマーカーのAssetID
  selectedMarkerId: null
}

const getters = {
  get: (state) => {
    return state
  },
  getSelectedModel: state => state.model.filter(item => item.AssetId === state.selectedMarkerId)
}

const actions = {
  /**
   * 初期読出し
   */
  load ({commit}, params) {
    console.log('initLoad')
    return new Promise((resolve, reject) => {
      let apigTypes = [
        'asset'
      ]
      let apigMethods = [
        'v1AssetListGet'
      ]
      let apigParameters = [
        params
      ]

      // プロジェクト一覧・管理者一覧を読出し
      ApiManager.request(
        apigTypes,
        apigMethods,
        apigParameters)
        .then(res => {
          commit('setModel', res[0].data.Assets)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  /**
   * 検索
   */
  search ({commit}, {query}) {
    commit('selectedMarker', null)
    commit('clearModel')

    console.log('search.query : ' + query)

    return new Promise((resolve, reject) => {
      let apigTypes = [
        'asset'
      ]
      let apigMethods = [
        'v1AssetListGet'
      ]
      let apigParameters = [
        {
          ShowAll: false
        }
      ]

      // プロジェクト一覧・管理者一覧を読出し
      ApiManager.request(
        apigTypes,
        apigMethods,
        apigParameters)
        .then(res => {
          commit('setModel', res[0].data.Assets)
          console.log('GoogleMap.model', state.model)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // mapにプロットするマーカーを追加
  addMarker ({commit}, marker) {
    commit('addMarker', marker)
  },
  // mapにプロットされたマーカーを全て削除
  clearMarkers ({commit}) {
    state.markerList.forEach(marker => {
      marker.setMap(null)
    })
    commit('clearMarkers')
  },
  // マーカーを選択
  selectMarker ({commit}, id) {
    commit('selectedMarker', id === undefined ? null : id)
  },
  // 初期化
  reset ({commit}) {
    // commit('clearMarkers')
    commit('clearModel')
    commit('selectedMarker', null)
  }
}

const mutations = {
  setGoogleMap (state, map) {
    state.map = map
  },
  setModel (state, data) {
    let list = []
    data.forEach(item => {
      // if (Math.random() < 0.1) {
        list.push($.extend(true, {
          position: {
            lat: 35.6745216 + Math.random() / 20 * (Math.random() < 0.5 ? 1 : -1),
            lng: 139.7000324 + Math.random() / 20 * (Math.random() < 0.5 ? 1 : -1)
          }
        }, item))
      // }
    })

    // let list = data.map(item => {
    //   return $.extend(true, {
    //     position: {
    //       lat: 35.6745216 + Math.random() / 20 * (Math.random() < 0.5 ? 1 : -1),
    //       lng: 139.7000324 + Math.random() / 20 * (Math.random() < 0.5 ? 1 : -1)
    //     }
    //   }, item)
    // })
    Vue.set(state, 'model', list)
    // state.inited = true
  },
  clearModel (state) {
    Vue.set(state, 'model', [])
  },
  setShowGnav (state, flag) {
    state.showGnav = flag
  },
  addMarker (state, marker) {
    state.markerList.push(marker)
  },
  clearMarkers (state) {
    Vue.set(state, 'markerList', [])
  },
  selectedMarker (state, id) {
    state.selectedMarkerId = id
  } 
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
