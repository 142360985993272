export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      Notice: {
        NoticeId: 1,
        SendUserIdentity: 'systemAdmin001',
        TenantIdentity: 'tenantIdentity001',
        NoticeType: 1,
        NoticeSubject: 'お知らせA',
        NoticeText: 'ああああああああああああああああああああああああああああああああああああああああ',
        LiveFlg: true,
        Note: '',
        CreatedDt: '2019-09-01 12:29:30',
        UpdatedDt: '2019-09-01 12:29:30'
      }
    }
  }
}
