import { mapGetters, mapActions } from 'vuex'
import Loading from '@/components/Loading'
import Constants from '@/utils/Constants'
import ApiManager from '@/utils/ApiManager'
import Validator from '@/utils/Validator'
import DndZone from '@/components/ui/DndZone'

export default {
  name: 'AssetRead',
  components: {
    Loading,
    DndZone
  },
  metaInfo () {
    this.setMeta({
      title: '橋梁データCSV読込み | ' + Constants.SiteInfo.title
    })
    return this.getMeta
  },
  data () {
    return {
      showLoading: false,
      // 入力値
      csvFile: null,
      isClickedConfirm: false,
      // DnD options
      dndOptions: {
        fileType: 'document'
      }
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapGetters({
      getMeta: 'meta/get'
    }),
    // validate
    csvFileIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validFile(this.csvFile)
    },
    formIsValid () {
      // return true
      return this.csvFileIsValid
    },
    disableConfirmButton () {
      return !Validator.validFile(this.csvFile)
    }
  },
  methods: {
    ...mapActions({
      setMeta: 'meta/set',
      initLoad: 'assetRead/initLoad',
      showError: 'error/show'
    }),
    init () {
      // データ読出し
      this.showLoading = true
      this.initLoad()
        .then(() => {
          this.showLoading = false
        })
    },
    /**
     * DnDコンポーネントから登録されたファイルリストを受取る
     */
    addFiles (params) {
      this.csvFile = params.fileList.length ? params.fileList[0] : null

      // // CSVの内容をログに表示
      // this.csvFile.text()
      //   .then(res => res.split('\n').map(s => s.split(',')))
      //   .then(res => console.log('array', parseCsv(res)));

      // const parseCsv = array => {
      //   const subjects = array.shift();
      //   return array.map(record => {
      //     return record.map((item, i) => {
      //       let o = {};
      //       o[subjects[i]] = record[i];
      //       return o;
      //     })
      //   });
      // };
    },
    clickConfirm () {
      if (!this.formIsValid) return

      this.isClickedConfirm = true

      let parameters = {
        File: this.csvFile,
        CsvTitleFlg: true
      }
      console.log('register data', parameters)

      ApiManager.request(
        ['asset'],
        ['v1AssetCsvPost'],
        [parameters])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            this.$router.push({name: 'Asset'})
            
          // API エラー
          } else if (res[0].data.Result === Constants.ApiResult.Failure) {
            this.showError(res[0].data)
            
          // システムエラー
          } else {
            this.showError(null)
          }
        })
        .catch(() => {
          this.showError(null)
        })
    }
  }
}
