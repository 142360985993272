import { mapState, mapGetters, mapActions } from 'vuex'
import Loading from '@/components/Loading'
import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'

export default {
  name: 'TenantConfirm',
  components: {
    Loading
  },
  data () {
    return {
      connecting: false
    }
  },
  computed: {
    ...mapState({
      tenantIdentity: state => state.tenantForm.targetId,
      values: state => state.tenantForm.values
    }),
    ...mapGetters({
      isRegister: 'tenantForm/isRegister',
      isModify: 'tenantForm/isModify'
    }),
    note () {
      return this.values.note ? this.values.note.replace(/\n/, '<br>') : ''
    },
    invalidFlag () {
      return this.values.invalidFlag ? '無効' : '有効'
    },
    registerLabel () {
      return this.isRegister ? '作成' : '登録'
    }
  },
  methods: {
    ...mapActions({
      showError: 'error/show'
    }),
    clickBack () {
      this.$router.go(-1)
    },
    clickRegister () {
      this.connecting = true

      let method = this.isRegister ? 'v1TenantPost' : 'v1TenantUpdate'
      let parameters = {
        TenantData: {
          TenantName: this.values.name,
          TenantNameKana: this.values.kana,
          TenantAddress: this.values.address,
          TenantAddressKana: this.values.addressKana,
          TantouDepartment: this.values.division,
          TantouName: this.values.person,
          TantouNameKana: this.values.personKana,
          TantouEmail: this.values.email,
          TantouPhone1: this.values.phone1,
          TantouPhone2: this.values.phone2,
          TantouPhone3: this.values.phone3,
          TantouAddress: this.values.contacts,
          Note: this.values.note
        }
      }
      if (this.isModify) {
        parameters.TenantData.TargetTenantIdentity = this.tenantIdentity
        parameters.TenantData.LiveFlg = !this.values.invalidFlag
      }
      console.log('register data', parameters)
      // return

      // 登録
      ApiManager.request(['tenant'], [method], [parameters])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            // 完了画面
            this.$router.push({name: 'TenantComplete'})

          // API エラー
          } else if (res[0].data.Result === Constants.ApiResult.Failure) {
            this.showError(res[0].data)

          // システムエラー
          } else {
            this.showError(null)
          }
          this.connecting = false
          
        })
        .catch(() => {
          this.showError(null)
          this.connecting = false
        })
    }
  }
}
