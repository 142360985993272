import { mapState, mapGetters,  mapActions} from 'vuex'
export default {
  name: 'Header',
  computed: {
    ...mapState({
      authorized: state => state.auth.authorized,
      requireChangePassword: state => state.auth.requireChangePassword,
      userRole: state => state.auth.userRole,
      activeAccountBox: state => state.accountBox.active
    }),
    ...mapGetters({
      userName: 'auth/userName',
      userRoleList: 'auth/userRoleList'
    }),
    userRoles () {
      return this.userRoleList.join('、')
    },
    showNavi () {
      return this.authorized && !this.requireChangePassword
    }
  },
  methods: {
    ...mapActions({
      signOut: 'auth/signOut',
      showAccountBox: 'accountBox/show',
      hideAccountBox: 'accountBox/hide'
    }),
    clickSignOut () {
      this.signOut()
        .then(() => {
          this.$router.push({name: 'Signin'})
        })
    },
    toggleAccountBox () {
      this.activeAccountBox ?
        this.hideAccountBox() : this.showAccountBox()
    }
  }
}