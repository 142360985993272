import { mapGetters, mapMutations } from 'vuex'
import Constants from '@/utils/Constants'
export default {
  name: 'NoticeComplete',
  data () {
    return {
      redirectTimer: null
    }
  },
  mounted () {
    this.resetForm()
    this.redirectTimer = setTimeout(() => {
      this.$router.push({name: 'Notice'})
    }, Constants.Params.CompletedRedirectDelay)
  },
  beforeDestroy () {
    if (this.redirectTimer) {
      clearTimeout(this.redirectTimer)
      this.redirectTimer = null
    }
  },
  computed: {
    ...mapGetters({
      isRegister: 'noticeForm/isRegister',
      isModify: 'noticeForm/isModify'
    }),
    description () {
      return this.isRegister ?
        '新規お知らせを登録しました。' :
        'お知らせを編集しました。'
    }
  },
  methods: {
    ...mapMutations({
      resetForm: 'noticeForm/reset'
    })
  }
}
