import Constants from '@/utils/Constants'

export default class ApiManager {
  /**
   * apiClientsを介してAPIリクエストをまとめて行う
   * @param {Array} types APIのタイプの配列
   * @param {Array} methods APIのメソッド名の配列
   * @param {Array} parameters APIのパスパラメータ・クエリパラメータの配列
   */
  static request (types, methods, parameters) {
    let promises = []
    let apiClients = {
      auth: window._API_.Authorization(),
      tenant: window._API_.Tenants(),
      user: window._API_.Users(),
      asset: window._API_.Assets(),
      notice: window._API_.Notices(),
      aws: window._API_.Aws(),
      util: window._API_.Utils()
    }
    for (let i = 0; i < methods.length; i++) {
      let method = window._API_.mode === 'stub' ? 'stub' + methods[i] : methods[i]
      // console.log(method)

      // APIが存在している時
      if (apiClients[types[i]] && apiClients[types[i]][method]) {
        promises.push(apiClients[types[i]][method](parameters[i]))

      // APIが存在しないのでモックデータを利用する時
      } else {
        console.log(method)
        
        // stubv1AuthLogin
        if (method === 'stubv1AuthLogin') {
          if (parameters[i].Id === 's') {
            promises.push(this.requestMock('stubv1AuthLoginSystemAdmin'))
          } else if (parameters[i].Id === 't') {
            promises.push(this.requestMock('stubv1AuthLoginTenantAdmin'))
          } else if (parameters[i].Id === 'tn') {
            promises.push(this.requestMock('stubv1AuthLoginTenantAdminFresher'))
          } else {
            promises.push(this.requestMock('stubv1AuthLoginTenantAdmin'))
          }

        // stubv1UserListGet
        } else if (method === 'stubv1UserListGet') {
          if (parameters[i].roles === Constants.UserRole[0].code) {
            promises.push(this.requestMock('stubv1SystemAdminListGet'))
          } else if (parameters[i].roles === Constants.UserRole[1].code) {
            promises.push(this.requestMock('stubv1TenantAdminListGet'))
          } else if (parameters[i].roles === Constants.UserRole[2].code) {
            promises.push(this.requestMock('stubv1ModeratorListGet'))
          } else if (parameters[i].roles === Constants.UserRole[4].code) {
            promises.push(this.requestMock('stubv1InspectorListGet'))
          } else if (parameters[i].roles === Constants.UserRole[6].code) {
            promises.push(this.requestMock('stubv1ObserverListGet'))
          } else if (parameters[i].roles === 'moderator_observer') {
            promises.push(this.requestMock('stubv1ModeratorObserverListGet'))
          } else {
            promises.push(this.requestMock(method))
          }

        // stubv1UserDetailGet
        } else if (method === 'stubv1UserDetailGet') {
          if (parameters[i].userIdentityIds) {
            promises.push(this.requestMock('stubv1UserDetailGet_' + parameters[i].userIdentityIds[0]))
          } else {
            promises.push(this.requestMock(method))
          }

        // stubv1TenantDetailGet
        } else if (method === 'stubv1TenantDetailGet') {
          if (parameters[i].tenantIdentities) {
            promises.push(this.requestMock('stubv1TenantDetailGet_' + parameters[i].tenantIdentities[0]))
          } else {
            promises.push(this.requestMock(method))
          }

        // stubv1AssetDetailGet
        } else if (method === 'stubv1AssetDetailGet') {
            console.log(parameters[i])
          if (parameters[i].assetIds) {
            promises.push(this.requestMock('stubv1AssetDetailGet_' + parameters[i].assetIds[0]))
          } else {
            promises.push(this.requestMock(method))
          }

        } else {
          promises.push(this.requestMock(method))
        }
      }
    }
    // return Promise.all(promises.map(p => p.catch(() => undefined)))
    return Promise.all(promises.map(p => p.catch((error) => error.response)))
    // return Promise.all(promises.map(p => p.catch((error) => {
    //   // console.log('response', error.response)
    //   // console.log('request', error.request)
    //   // console.log('message', error.message)
    //   // console.log('config', error.config)
    //   return error.response
    // })))
  }

  /**
   * モックデータを取得する
   * @param {String} fileName モックデータを取得するファイル名
   */
  static requestMock (fileName) {
    let data = require('../../test/mock/' + fileName).default.mock
    return new Promise(function (resolve) {
      setTimeout(resolve(data), 2000)
    })
  }
}
