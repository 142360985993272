const state = {

}

const getters = {
  get: state => state
}

const actions = {
  /**
   * 初期読出し
   */
  // eslint-disable-next-line
  initLoad ({commit}) {
    // eslint-disable-next-line
    return new Promise((resolve, reject) => {
      resolve()
    })
  }
}

const mutations = {
  
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
