<template>
  <div id="app">
    <div class="wrapper">
      <Header v-if="showHeader"></Header>
      <AccountBox></AccountBox>
      <Aside></Aside>
      <div
        class="content-wrapper"
        :class="{notlogin: hideAside}"
        v-if="enable"
      >
        <router-view/>
      </div>
      <Footer v-if="showFooter"></Footer>
    </div>
    <Error id="errorDialog" />
    <Alert id="alertDialog" />
  </div>
</template>

<style>
.content-wrapper.notlogin {
  margin-left: 0;
}
</style>

<script>
import { mapState, mapActions } from 'vuex'
import Header from '@/components/Header'
import AccountBox from '@/components/AccountBox'
import Aside from '@/components/Aside'
import Footer from '@/components/Footer'
import Error from '@/components/Dialog/Error'
import Alert from '@/components/Dialog/Alert'
export default {
  name: 'App',
  components: {
    Header,
    AccountBox,
    Aside,
    Footer,
    Error,
    Alert
  },
  data () {
    return {
      enable: false
    }
  },
  mounted () {
    this.enable = false

    this.$nextTick(() => {
      if (this.$route.meta.requiresAuth) {
        // ローカルストレージに前回保存したユーザー情報があれば
        if (window.sessionStorage.user) {
          this.enable = true
        } else {
          this.signOut().then(() => {
            this.$router.push({name: 'Signin'})
            this.enable = true
          })
        }
      } else {
        this.enable = true
      }
    })

    // // ローカルストレージに前回保存したユーザー情報があれば
    // if (window.sessionStorage.user) {
    //   this.enable = true
    // } else {
    //   this.$router.push({name: 'Signin'})
    //   this.enable = true
    // }

    // // ローカルストレージに前回保存したユーザー情報があれば
    // if (window.sessionStorage.user) {
    //   // ブラウザ起動時にローカルストレージのトークンをリフレッシュ
    //   this.refreshToken()
    //     .then(res => {
    //       console.log('refreshToken.SUCCESS', res)
    //       window.sessionStorage.tokenRefreshed = 'true'
    //       this.enable = true
    //     })
    //     .catch(error => {
    //       console.log('refreshToken.ERROR', error)
    //       this.signOut().then(() => {
    //         this.$router.push({name: 'Signin'})
    //         this.enable = true
    //       })
    //     })
    // } else {
    //   // this.$router.push({name: 'Signin'})
    //   this.enable = true
    // }
  },
  computed: {
    ...mapState({
      authorized: state => state.auth.authorized,
      requireChangePassword: state => state.auth.requireChangePassword
    }),
    hideAside () {
      return !this.authorized || this.requireChangePassword
    },
    showHeader () {
      return !this.isSigninPage
    },
    showFooter () {
      return !this.isSigninPage
    },
    isSigninPage () {
      return this.$route.name === 'Signin'
    }
  },
  methods: {
    ...mapActions({
      refreshToken: 'auth/refreshToken',
      signOut: 'auth/signOut'
    })
  }
}
</script>
