export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      User: {
        UserIdentity: 'identifier01',
        Token: 'xxxxxaccesstokenxxxxx',
        TenantIdentity: 'tenantMD5Hashxxxxxxxxxxxxxxxx1',
        TenantName: 'tenant name',
        Role: 1,
        // 1 : system administrator
        // 2 : tenant administrator
        // 4 : moderator
        // 8 : pilot
        // 16 : inspector
        // 32 : customer
        UserName: 'シスアド 太郎'
      }
    }
  }
}