import { mapState, mapGetters, mapActions } from 'vuex'
import Loading from '@/components/Loading'
import Constants from '@/utils/Constants'
import Utility from '@/utils/Utility'

export default {
  name: 'TenantAdmin',
  components: {
    Loading
  },
  metaInfo () {
    this.setMeta({
      title: 'テナント管理者一覧 | ' + Constants.SiteInfo.title
    })
    return this.getMeta
  },
  data () {
    return {
      showLoading: false,
      registerHash: Constants.Params.RegisterHash,
      // テーブルオプション
      tableColumns: [
        'TenantName',
        'Name',
        'Phone1',
        'UserRole',
        'CreatedAt'
      ],
      tableData: [],
      tableOptions: {
        headings: {
          TenantName: '会社名',
          Name: '担当者名',
          Phone1: '担当者電話番号',
          UserRole: 'ロール',
          CreatedAt: '作成日'
        },
        columnsClasses: {
          TenantName: '',
          Name: '',
          UserRole: 'wp-20',
          CreatedAt: 'wp-15'
        },
        sortable: [
          'TenantName',
          'Name',
          'UserRole',
          'CreatedAt'
        ],
        filterable: false,
        texts: Constants.TableOptions.getTexts('テナント管理者'),
        perPage: Constants.TableOptions.perPage,
        perPageValues: Constants.TableOptions.perPageValues,
        pagination: Constants.TableOptions.pagination,
        resizableColumns: false,
      },
      // 絞込み
      showAll: 'true',
      role: null
    }
  },
  mounted () {
    // 絞り込みの初期値をテナントアドミンに変更
    this.role = Constants.UserRole[1].code
    // 初期化
    this.init()
  },
  computed: {
    ...mapState({
      userModel: state => state.tenantAdmin.userModel
    }),
    ...mapGetters({
      getMeta: 'meta/get',
      isSystemAdmin: 'auth/isSystemAdmin',
      isTenantAdmin: 'auth/isTenantAdmin'
    }),
    roleList () {
      let list = []
      Constants.UserRole.forEach(item => {
        if (item.code === Constants.UserRole[0].code ||
          item.code === Constants.UserRole[1].code) {
          list.push(item)
        }
      })
      return list
    },
    showResult () {
      return !this.showLoading && this.tableData.length > 0
    }
  },
  methods: {
    ...mapActions({
      setMeta: 'meta/set',
      loadUserList: 'tenantAdmin/loadUserList',
      showError: 'error/show'
    }),
    init () {
      // データ読出し
      this.showLoading = true
      this.loadUserList({
        showAll: true,
        role: Constants.UserRole[1].code
      })
        .then(() => {
          this.tableData = $.extend(this.userModel, [], true)
          this.showLoading = false
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
        })
    },
    getViewData (props) {
      let key = props.column
      let value = props.row[key]

      if (key === 'UserRole') return Utility.getRoleLabelByInt(value)
      if (key === 'Phone1') return value + '-' + props.row.Phone2 + '-' + props.row.Phone3
      if (key === 'CreatedAt') return Utility.getJSTDate(value, 'YYYY/MM/DD HH:mm')
      return value
    },
    changePage () {
      // console.log(this.$refs.table.page)
    },
    changeFilters () {
      let parameters = {}
      if (this.showAll !== null) parameters.showAll = this.showAll === 'true'
      if (this.role !== null && this.role !== 'null') parameters.role = this.role

      // ユーザー一覧を読出し
      this.showLoading = true
      this.loadUserList(parameters)
        .then(() => {
          this.tableData = $.extend(this.userModel, [], true)
          this.showLoading = false
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
        })
    }
  }
}