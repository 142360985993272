import { mapState, mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import Loading from '@/components/Loading'
import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Utility from '@/utils/Utility'

export default {
  name: 'NoticeConfirm',
  components: {
    Loading
  },
  data () {
    return {
      connecting: false
    }
  },
  computed: {
    ...mapState({
      noticeId: state => state.noticeForm.targetId,
      values: state => state.noticeForm.values
    }),
    ...mapGetters({
      isRegister: 'noticeForm/isRegister',
      isModify: 'noticeForm/isModify'
    }),
    startDate () {
      return Utility.getJSTDate(this.values.startDate, 'YYYY/MM/DD HH:mm')
    },
    endDate () {
      if (!this.values.endDate) return '--'
      return Utility.getJSTDate(this.values.endDate, 'YYYY/MM/DD HH:mm')
    },
    body () {
      return this.values.body ? this.values.body.split('\n').join('<br>') : ''
    },
    registerLabel () {
      return this.isRegister ? '作成' : '登録'
    },
    invalidFlag () {
      return this.values.invalidFlag ? '無効' : '有効'
    }
  },
  methods: {
    ...mapActions({
      showError: 'error/show'
    }),
    clickBack () {
      this.$router.go(-1)
    },
    clickRegister () {
      this.connecting = true

      let method = this.isRegister ?
        'v1NoticePost' :
        'v1NoticeUpdate'
      let parameters = {
        NoticeData: {
          NoticeSubject: this.values.subject,
          NoticeText: this.values.body,
          OpenTermStartDt: moment(this.values.startDate).utc().utcOffset("+09:00").format()
          // OpenTermStartDt: moment(this.values.startDate).subtract('hours', 9).utc().utcOffset("+09:00").format()
        }
      }
      if (this.values.endDate) {
        parameters.NoticeData.OpenTermEndDt = moment(this.values.endDate).utc().utcOffset("+09:00").format()
        // parameters.NoticeData.OpenTermEndDt = moment(this.values.endDate).subtract('hours', 9).utc().utcOffset("+09:00").format()
      }
      if (this.isModify) {
        parameters.NoticeData.NoticeId = Number(this.noticeId)
        parameters.NoticeData.LiveFlg = !this.values.invalidFlag
      }
      console.log('register data', parameters)

      // 登録
      ApiManager.request(
        ['notice'],
        [method],
        [parameters])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            this.$router.push({name: 'NoticeComplete'})

          // API エラー
          } else if (res[0].data.Result === Constants.ApiResult.Failure) {
            this.showError(res[0].data)
            
          // システムエラー
          } else {
            this.showError(null)
          }
          this.connecting = false
          
        })
        .catch(() => {
          this.showError(null)
          this.connecting = false
        })
    }
  }
}
