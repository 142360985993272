import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Utility from '@/utils/Utility'
import Vue from 'vue'

const state = {
  tenantModel: []
}

const getters = {
  get: state => state
}

const actions = {
  /**
   * テナント一覧を読出し
   */
  loadTenantList ({commit}, {showAll}) {
    return new Promise((resolve, reject) => {
      let parameters = {}
      if (showAll !== undefined) parameters.showAll = showAll

      ApiManager.request(
        ['tenant'],
        ['v1TenantListGet'],
        [parameters])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            commit('setTenantModel', res[0].data.Tenants)
            console.log('@tenantModel', state.tenantModel)
            resolve()

          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {
  setTenantModel (state, data) {
    data.sort(Utility.sortByKey('CreatedAt', false))
    Vue.set(state, 'tenantModel', data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
