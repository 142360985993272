import { mapState, mapGetters, mapActions } from 'vuex'
import Loading from '@/components/Loading'
import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'

export default {
  name: 'AssetConfirm',
  components: {
    Loading
  },
  data () {
    return {
      connecting: false
    }
  },
  computed: {
    ...mapState({
      assetId: state => state.assetForm.targetId,
      values: state => state.assetForm.values
    }),
    ...mapGetters({
      isRegister: 'assetForm/isRegister',
      isModify: 'assetForm/isModify'
    }),
    completionYear () {
      if (this.values.completionMonth) {
        return this.values.completionYear + '年' +
          this.values.completionMonth + '月'
      } else {
        return this.values.completionYear
      }
    },
    roadAdminKbn () {
      return Constants.AssetConst.RoadAdminPeriod[String(this.values.roadAdminKbn)]
    },
    prefectureLabel () {
      return Constants.Prefecture[this.values.prefecture]
    },
    latitude () {
      return this.values.latitude_60_degree + '° ' +
        this.values.latitude_60_minutes + '&#39;' +
        this.values.latitude_60_seconds + '&quot;'
    },
    longitude () {
      return this.values.longitude_60_degree + '° ' +
        this.values.longitude_60_minutes + '&#39;' +
        this.values.longitude_60_seconds + '&quot;'
    },
    bridgeLength() {
      return this.values.length
        ? this.values.length + ' m'
        : '';
    },
    bridgeSpanner() {
      return this.values.spanner
        ? this.values.spanner + ' m'
        : '';
    },
    bridgeWidth() {
      return this.values.width
        ? this.values.width + ' m'
        : '';
    },
    fallPreventionEq () {
      if (this.values.fallPreventionEq === null) {
        return ''
      } else {
        return Constants.AssetConst.FallPrevention[this.values.fallPreventionEq]
      }
    },
    file () {
      if (this.values.file === null) {
        return ''
      } else {
        return this.values.file.name
      }
    },
    note () {
      return this.values.note ? this.values.note.replace(/\n/, '<br>') : ''
    },
    invalidFlag () {
      return this.values.invalidFlag ? '無効' : '有効'
    },
    registerLabel () {
      return this.isRegister ? '作成' : '登録'
    }
  },
  methods: {
    ...mapActions({
      showError: 'error/show'
    }),
    clickBack () {
      this.$router.go(-1)
    },
    clickRegister () {
      this.connecting = true

      let parameters = {
        AssetData: {
          Name: this.values.name,
          Prefecture: this.values.prefecture,
          City: this.values.city,
          Address: this.values.address,
          Latitude_60_degree: Number(this.values.latitude_60_degree),
          Latitude_60_minutes: Number(this.values.latitude_60_minutes),
          Latitude_60_seconds: Number(this.values.latitude_60_seconds),
          Longitude_60_degree: Number(this.values.longitude_60_degree),
          Longitude_60_minutes: Number(this.values.longitude_60_minutes),
          Longitude_60_seconds: Number(this.values.longitude_60_seconds),
          LocationId: this.values.locationId
            ? Number(this.values.locationId)
            : '',
          CompletionYear: this.values.completionYear,
          Constructor: this.values.constructorName,
          RoadAdmin: this.values.roadAdmin,
          RoadAdminKbn: this.values.roadAdminKbn,
          OfficeContact: this.values.officeContact,
          OfficeName: this.values.officeName,
          BridgeForm: this.values.bridgeForm,
          Form1: this.values.form1,
          Length: this.values.length,
          Spanner: this.values.spanner,
          Width: this.values.width,
          Note: this.values.note
        }
      }
      // optional
      if (this.values.lineName)
          parameters.AssetData.LineName = this.values.lineName
      if (this.values.completionMonth)
          parameters.AssetData.CompletionMonth = this.values.completionMonth
      if (this.values.bridgeAdmin)
          parameters.AssetData.BridgeAdmin = this.values.bridgeAdmin
      if (this.values.form2)
          parameters.AssetData.Form2 = this.values.form2
      if (this.values.form3)
          parameters.AssetData.Form3 = this.values.form3
      if (this.values.fallPreventionEq !== null)
          parameters.AssetData.FallPreventionEq = this.values.fallPreventionEq
      if (this.values.file !== null)
          parameters.AssetData.Figure1 = this.values.file

      if (this.isModify) {
        parameters.AssetId = Number(this.assetId)
        parameters.AssetData.LiveFlg = !this.values.invalidFlag
      }
      console.log('register data', parameters)

      // 登録
      ApiManager.request(
        ['asset'],
        [this.isRegister ? 'v1AssetPost' : 'v1AssetUpdate'],
        [parameters])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            this.$router.push({name: 'AssetComplete'})

          // API エラー
          } else if (res[0].data.Result === Constants.ApiResult.Failure) {
            this.showError(res[0].data)
            
          // システムエラー
          } else {
            this.showError(null)
          }
          this.connecting = false
          
        })
        .catch(() => {
          this.showError(null)
          this.connecting = false
        })
    }
  }
}
