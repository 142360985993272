export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      User: {
        UserIdentity: 'identity id',
        Role: 8,
        TenantIdentity: 'xxxxx',
        TenantName: 'tenant name'
      },
      Asset: {
        AssetId: 1,
        TenantIdentity: 'xxxxx',
        AssetName: 'アセット001',
        AssetTypeId: 2,
        Prefecture: '13',
        Branch: '支店A',
        Area: '渋谷',
        Building: 'マークシティー',
        Address: '東京都渋谷区渋谷2-1-1',
        Latitude: 35.6579883,
        Longitude: 139.6958671,
        Note: '備考テキスト\n備考テキスト',
        LiveFlg: true,
        CreatedDt: '2019-09-02',
        UpdatedDt: '2019-10-01'
      }
    }
  }
}
