import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import Validator from '@/utils/Validator'
// import Utility from '@/utils/Utility'

export default {
  name: 'InspectorInput',
  data () {
    return {
      // 入力値
      company: '',
      companyKana: '',
      leader: '',
      leaderKana: '',
      name: '',
      kana: '',
      division: '',
      loginId: '',
      userRole: 'inspector',
      email: '',
      phone1: '',
      phone2: '',
      phone3: '',
      address: '',
      note: '',
      tenantIdentity: null,
      // flag
      invalidFlag: false,
      isClickedConfirm: false
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapState({
      label: state => state.label.model,
      userIdentity: state => state.inspectorForm.targetId,
      values: state => state.inspectorForm.values
    }),
    ...mapGetters({
      isSystemAdmin: 'auth/isSystemAdmin',
      isTenantAdmin: 'auth/isTenantAdmin',
      isRegister: 'inspectorForm/isRegister',
      isModify: 'inspectorForm/isModify'
    }),
    // validate
    roleIsValid () {
      if (!this.isClickedConfirm) return true
      return this.roleIsSelected
    },
    companyIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.company)
    },
    companyKanaIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.companyKana)
    },
    nameIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.name)
    },
    kanaIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.kana)
    },
    loginIdIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.loginId)
    },
    roleIsSelected () {
      return this.userRole !== ''
    },
    emailIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.email)
    },
    phoneIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.phone1) &&
        Validator.validText(this.phone2) &&
        Validator.validText(this.phone3)
    },
    noteIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.note)
    },
    formIsValid () {
      // return true
      return this.roleIsValid &&
        this.companyIsValid &&
        this.companyKanaIsValid &&
        this.nameIsValid &&
        this.kanaIsValid &&
        this.loginIdIsValid &&
        this.emailIsValid &&
        this.phoneIsValid
    },
    disableConfirmButton () {
      // console.log(this.firmIsValid)
      return !this.roleIsSelected ||
        !Validator.validText(this.company) ||
        !Validator.validText(this.companyKana) ||
        !Validator.validText(this.name) ||
        !Validator.validText(this.kana) ||
        !Validator.validText(this.loginId) ||
        !Validator.validText(this.email) ||
        !Validator.validText(this.phone1) ||
        !Validator.validText(this.phone2) ||
        !Validator.validText(this.phone3)
    }
  },
  methods: {
    ...mapActions({
      resetPassword: 'inspectorForm/resetPassword'
    }),
    ...mapMutations({
      setValues: 'inspectorForm/setValues'
    }),
    init () {
      // 確認画面から戻った時、編集に宣した時に値を挿入
      // if (this.values) this.isClickedConfirm = true
      if (this.values.userRole) this.userRole = this.values.userRole
      if (this.values.company) this.company = this.values.company
      if (this.values.companyKana) this.companyKana = this.values.companyKana
      if (this.values.leader) this.leader = this.values.leader
      if (this.values.leaderKana) this.leaderKana = this.values.leaderKana
      if (this.values.name) this.name = this.values.name
      if (this.values.kana) this.kana = this.values.kana
      if (this.values.division) this.division = this.values.division
      if (this.values.loginId) this.loginId = this.values.loginId
      if (this.values.tenantIdentity) this.tenantIdentity = this.values.tenantIdentity
      if (this.values.email) this.email = this.values.email
      if (this.values.phone1) this.phone1 = this.values.phone1
      if (this.values.phone2) this.phone2 = this.values.phone2
      if (this.values.phone3) this.phone3 = this.values.phone3
      if (this.values.address) this.address = this.values.address
      if (this.values.note) this.note = this.values.note
      if (this.values.invalidFlag) this.invalidFlag = this.values.invalidFlag
    },
    changeNote (event) {
      // this.input.note = event.target.value.replace(/\n/, '<br>')
      this.note = event.target.value
    },
    clickConfirm () {
      this.isClickedConfirm = true
      // console.log(this.invalidFlag)

      // validate
      if (this.formIsValid) {
        let data = $.extend(true, {}, this.values)
        data.userRole = this.userRole
        data.company = this.company
        data.companyKana = this.companyKana
        data.leader = this.leader
        data.leaderKana = this.leaderKana
        data.name = this.name
        data.kana = this.kana
        data.division = this.division
        data.loginId = this.loginId
        data.email = this.email
        data.phone1 = this.phone1
        data.phone2 = this.phone2
        data.phone3 = this.phone3
        data.address = this.address
        data.note = this.note
        data.tenantIdentity = this.tenantIdentity
        if (this.isModify) data.invalidFlag = this.invalidFlag
        this.setValues(data)
        this.$router.push({name: 'InspectorConfirm'})
      }
    },
    clickResetPasswordConfirm () {
      $('#modal-reset-confirm').modal('show')
      // // console.log(this.userIdentity)
      // this.resetPassword()
      //   .then(() => {
      //     this.$router.push({name: 'InspectorComplete'})
      //   })
      //   .catch(() => {

      //   })
    },
    clickClose () {
      $('#modal-reset-confirm').modal('hide')
    },
    clickResetPassword () {
      // // console.log(this.userIdentity)
      this.resetPassword()
        .then(() => {
          this.$router.push({name: 'InspectorComplete'})
        })
        .catch(() => {

        })
      $('#modal-reset-confirm').modal('hide')
    }
  }
}
