import { mapState, mapGetters, mapActions } from 'vuex'
import Loading from '@/components/Loading'
import Constants from '@/utils/Constants'
import Validator from '@/utils/Validator'

export default {
  name: 'Signin',
  components: {
    Loading
  },
  metaInfo () {
    this.setMeta({
      title: 'ログイン | ' + Constants.SiteInfo.title
    })
    return this.getMeta
  },
  data () {
    return {
      loginId: '',
      password: '',
      connecting: false
    }
  },
  computed: {
    ...mapState({
      requireChangePassword: state => state.auth.requireChangePassword
    }),
    ...mapGetters({
      getMeta: 'meta/get',
      isSystemAdmin: 'auth/isSystemAdmin',
      isTenantAdmin: 'auth/isTenantAdmin'
    }),
    // validate
    loginIdIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.loginId)
    },
    passwordIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.password)
    },
    formIsValid () {
      // return true
      return this.loginIdIsValid &&
        this.passwordIsValid
    },
    disableConfirmButton () {
      if (this.connecting) return true
      return !Validator.validText(this.loginId) ||
        !Validator.validText(this.password)
    }
  },
  methods: {
    ...mapActions({
      setMeta: 'meta/set',
      signIn: 'auth/signIn',
      showError: 'error/show'
    }),
    clickSignIn () {
      if (!this.formIsValid) return
      this.connecting = true

      // ログイン
      this.signIn({
        loginId: this.loginId,
        password: this.password
      })
        .then(res => {
          console.log('signin', res)
          // ChangePasswordがtrueの場合は、本パスワードの登録へ
          if (this.requireChangePassword) {
            this.$router.push({name: 'Register'})

          // falseの場合は、ログイン権限に応じて遷移
          } else {
            this.$router.push(this.$route.query.redirect ||
              {name: this.isSystemAdmin ? 'Tenant' : 'Notice'})
              // {name: this.isSystemAdmin ? 'Tenant' : 'Moderator'})
          }
          this.connecting = false

        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
          this.connecting = false
        })
    }
  }
}
