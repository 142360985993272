import { mapState, mapGetters, mapActions } from 'vuex'
import Loading from '@/components/Loading'
import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'

export default {
  name: 'TenantAdminConfirm',
  components: {
    Loading
  },
  data () {
    return {
      connecting: false
    }
  },
  computed: {
    ...mapState({
      userIdentity: state => state.tenantAdminForm.targetId,
      values: state => state.tenantAdminForm.values
    }),
    ...mapGetters({
      isSystemAdmin: 'auth/isSystemAdmin',
      isTenantAdmin: 'auth/isTenantAdmin',
      isRegister: 'tenantAdminForm/isRegister',
      isModify: 'tenantAdminForm/isModify',
      tenantIdentity: 'auth/tenantIdentity',
      getFirmName: 'tenantAdminForm/getFirmName',
      getTenantName: 'tenantAdminForm/getTenantName'
    }),
    note () {
      return this.values.note ? this.values.note.replace(/\n/, '<br>') : ''
    },
    invalidFlag () {
      return this.values.invalidFlag ? '無効' : '有効'
    },
    tenantName () {
      if (this.values.tenantIdentity == null) return ''
      return this.getTenantName(this.values.tenantIdentity)
    }
  },
  methods: {
    ...mapActions({
      showError: 'error/show'
    }),
    clickBack () {
      this.$router.go(-1)
    },
    clickRegister () {
      this.connecting = true

      let method = this.isRegister ? 'v1UserPost' : 'v1UserUpdate'
      let parameters = {
        UserData: {
          UserRole: Constants.UserRole[1].code,
          Name: this.values.name,
          NameKana: this.values.kana,
          Division: this.values.division,
          Email: this.values.email,
          Phone1: this.values.phone1,
          Phone2: this.values.phone2,
          Phone3: this.values.phone3,
          Address: this.values.address,
          Note: this.values.note,
        }
      }
      if (this.isRegister) {
        parameters.UserData.UserTenantIdentity = this.values.tenantIdentity
        parameters.UserData.LoginId = this.values.loginId
      }
      if (this.isModify) {
        parameters.UserData.TargetUserIdentity = this.userIdentity
        parameters.UserData.LiveFlg = !this.values.invalidFlag
      }
      console.log('register data', parameters)
      // return

      // 登録
      ApiManager.request(['user'], [method], [parameters])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            this.$router.push({name: 'TenantAdminComplete'})

          // API エラー
          } else if (res[0].data.Result === Constants.ApiResult.Failure) {
            this.showError(res[0].data)
            
          // システムエラー
          } else {
            this.showError(null)
          }
          this.connecting = false
          
        })
        .catch(() => {
          this.showError(null)
          this.connecting = false
        })
    }
  }
}
