export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      Tenant: {
        TenantId: 1,
        TenantIdentity: 'tenantmd5hashxxxxxxxxxxxxxxxx1',
        TenantName: 'テナントaaa',
        Abbreviation: 'aaa',
        LiveFlg: true,
        TantouDepartment: '担当部署',
        TantouName: '担当者名',
        TantouEmail: 'aaa@aaa.com',
        TantouTelephone: '03-3333-3333',
        Note: '備考\n備考',
        CreatedDt: '2019-08-02',
        UpdatedDt: '2019-09-20',
        ProjectCount: 4
      }
    }
  }
}