import 'babel-polyfill'
import Vue from 'vue'
import Vuex from 'vuex'
// common
import config from '@/store/common/config'
import auth from '@/store/common/auth'
import meta from '@/store/common/meta'
// view
import mypage from '@/store/views/mypage'
import register from '@/store/views/register'
import tenant from '@/store/views/tenant'
import tenantForm from '@/store/views/tenantForm'
import asset from '@/store/views/asset'
import assetForm from '@/store/views/assetForm'
import assetRead from '@/store/views/assetRead'
import tenantAdmin from '@/store/views/tenantAdmin'
import tenantAdminForm from '@/store/views/tenantAdminForm'
import moderator from '@/store/views/moderator'
import moderatorForm from '@/store/views/moderatorForm'
import inspector from '@/store/views/inspector'
import inspectorForm from '@/store/views/inspectorForm'
import notice from '@/store/views/notice'
import noticeForm from '@/store/views/noticeForm'
import account from '@/store/views/account'
// components
import alert from '@/store/components/alert'
import error from '@/store/components/error'
import accountBox from '@/store/components/accountBox'
import google from '@/store/components/google'
import projectMap from '@/store/components/projectMap'
import assetDetailModal from '@/store/components/assetDetailModal'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    // common
    config: config,
    auth: auth,
    meta: meta,
    // view
    mypage: mypage,
    register: register,
    tenant: tenant,
    tenantForm: tenantForm,
    asset: asset,
    assetForm: assetForm,
    assetRead: assetRead,
    tenantAdmin: tenantAdmin,
    tenantAdminForm: tenantAdminForm,
    moderator: moderator,
    moderatorForm: moderatorForm,
    inspector: inspector,
    inspectorForm: inspectorForm,
    notice: notice,
    noticeForm: noticeForm,
    account: account,
    // components
    alert: alert,
    error: error,
    accountBox: accountBox,
    google: google,
    projectMap: projectMap,
    assetDetailModal: assetDetailModal,
  }
})
