import { mapGetters, mapActions, mapMutations } from 'vuex'
import Loading from '@/components/Loading'
import Constants from '@/utils/Constants'

export default {
  name: 'TenantForm',
  components: {
    Loading
  },
  metaInfo () {
    this.setMeta({
      title: this.pageTitle + ' | ' + Constants.SiteInfo.title
    })
    return this.getMeta
  },
  props: {
    tenantIdentity: String
  },
  data () {
    return {
      showLoading: false
    }
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    this.resetForm()
  },
  computed: {
    ...mapGetters({
      getMeta: 'meta/get',
      isRegister: 'tenantForm/isRegister',
      isModify: 'tenantForm/isModify'
    }),
    pageTitle () {
      if (this.isRegister) return 'テナント新規作成'
      return 'テナント編集'
    }
  },
  methods: {
    ...mapActions({
      setMeta: 'meta/set',
      initLoad: 'tenantForm/initLoad',
      showError: 'error/show'
    }),
    ...mapMutations({
      resetForm: 'tenantForm/reset'
    }),
    init () {
      // データ読出し
      this.showLoading = true
      this.initLoad({
        targetId: this.tenantIdentity
      })
        .then(() => {
          this.showLoading = false
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
        })
    }
  }
}
