import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Utility from '@/utils/Utility'
import Vue from 'vue'

const state = {
  assetModel: []
}

const getters = {
  get: state => state
}

const actions = {
  /**
   * アセット一覧を読出し
   */
  loadAssetList ({commit}, parameters) {
    return new Promise((resolve, reject) => {
      ApiManager.request(
        ['asset'],
        ['v1AssetListGet'],
        [parameters])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            commit('setAssetModel', res[0].data.Assets)
            console.log('@assetModel', state.assetModel)
            resolve()

          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {
  setAssetModel (state, data) {
    data.sort(Utility.sortByKey('CreatedAt', false))
    Vue.set(state, 'assetModel', data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
