import Constants from '@/utils/Constants'

const state = {
  revision: Constants.Revision
}

const getters = {
  get: state => state,
  // release mode
  privateRelease: state => state.revision === 0,
  publicRelease: state => state.revision === 1
}

const actions = {
  // set ({commit}, metaData) {
  //   commit('set', metaData)
  // }
}

const mutations = {
  // set (state, metaData) {
  //   state['label'] = Labels
  // }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
