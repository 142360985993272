import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Utility from '@/utils/Utility'
import md5 from 'js-md5'

/* 別Storeへの参照方法
 * state     : this.state.scoopMarketFilters.xxxxx
 * getters   : this.getters['scoopMarketFilters/xxxxx']
 * actions   : this.dispatch('scoopMarketFilters/xxxxx', parameters)
 * mutations : this.commit('scoopMarketFilters/xxxxx', parameters)
 */

const state = {
  authorized: false,
  userModel: null,
  userRole: '0000000',
  requireChangePassword: false
}

const getters = {
  get: (state) => {
    return state
  },
  tenantIdentity: state => state.userModel.TenantIdentity,
  userIdentity: state => state.userModel.UserIdentity,
  loginId: state => state.userModel ?
    state.userModel.LoginId || '' : '',
  userName: state => state.userModel ?
    state.userModel.UserName || '' : '',
  // userRole
  isSystemAdmin: state => state.userRole.charAt(0) === '1',
  isTenantAdmin: state => state.userRole.charAt(1) === '1',
  isModerator: state => state.userRole.charAt(2) === '1',
  isPilot: state => state.userRole.charAt(3) === '1',
  isInspector: state => state.userRole.charAt(4) === '1',
  isCustomer: state => state.userRole.charAt(5) === '1',
  userRoleList: state => {
    let list = []
    if (getters.isSystemAdmin(state)) list.push(Constants.UserRole[0].label)
    if (getters.isTenantAdmin(state)) list.push(Constants.UserRole[1].label)
    if (getters.isModerator(state)) list.push(Constants.UserRole[2].label)
    if (getters.isPilot(state)) list.push(Constants.UserRole[3].label)
    if (getters.isInspector(state)) list.push(Constants.UserRole[4].label)
    if (getters.isCustomer(state)) list.push(Constants.UserRole[5].label)
    return list
  }
}

const actions = {
  /**
   * ログイン
   */
  signIn ({commit}, {loginId, password}) {
    return new Promise((resolve, reject) => {
      ApiManager.request(['auth'], ['v1AuthLogin'], [{
        Id: loginId,
        Password: password
      }])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            // md5のハッシュをチェック
            let userIdentity = res[0].data.User.UserIdentity
            let role = res[0].data.User.Role
            let timeStamp = res[0].data.User.TimeStamp
            let secretKey = window._API_.sk
            let checkStr = res[0].data.User.CheckStr
            // console.log('userIdentity', userIdentity)
            // console.log('role', role)
            // console.log('timeStamp', timeStamp)
            // console.log('secretKey', secretKey)
            let hash = md5(userIdentity + role + timeStamp + secretKey)
            console.log('checkStr', checkStr)
            console.log('hash', hash)
            
            if (checkStr === hash) {
              let data = $.extend(true, res[0].data.User, {LoginId: loginId})
              commit('signIn', data)
              // commit('signIn', res[0].data.User)
              resolve(res)
              
            } else {
              reject({
                Result: 'failure',
                Errors: [
                  {
                    Code: '9999',
                    Message: 'レスポンスに異常が認められました。'
                  }
                ]
              })
            }
            
          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  /**
   * ログアウト
   */
  signOut ({commit}) {
    return new Promise((resolve) => {
      commit('signOut')
      resolve()
    })
  },
  /**
   * トークンを更新
   */
  refreshToken ({commit}) {
    return new Promise((resolve, reject) => {
      ApiManager.request(['auth'], ['v1AuthRefreshToken'], [])
        .then(res => {
          // console.log('@ApiManager.signin.success : ', res)
          if (res[0].data.Result === 'success') {
            commit('refreshedToken', res[0].data.Token)
            resolve(res)
          } else {
            reject(res)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  /**
   * ローカルストレージにユーザー情報が保持されているかチェック
   */
  checkAuth ({commit}) {
    return new Promise((resolve, reject) => {
      if (window.sessionStorage.user) {
        commit('authed', window.sessionStorage.user)
        if (state.requireChangePassword) {
          resolve('Register')
        } else {
          resolve()
        }
      } else {
        reject()
      }
    })
  },
  /**
   * 新しいパスワードを登録
   */
  updatePassword ({commit}, {token, email, password}) {
    return new Promise((resolve, reject) => {
      ApiManager.request(['auth'], ['v1AuthReminderUpdate'], [{
        PasswordRestUrlToken: token,
        Email: email,
        NewPassword: password
      }])
        .then(res => {
          // console.log('@ApiManager.signin.success : ', res)
          if (res[0].data.Result === 'success') {
            commit('signIn', res[0].data.User)
            resolve(res)
          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}

const mutations = {
  signIn (state, data) {
    window.sessionStorage.user = JSON.stringify(data)
    state.authorized = true
    state.userModel = data
    // state.userRole = ('00000' + state.userModel.Role.toString(2)).slice(-6)
    state.userRole = Utility.roleToBit(state.userModel.Role)
    // console.log(data)
    if (data.ChangePassword) {
      state.requireChangePassword = true
    } else {
      state.requireChangePassword = false
    }
  },
  signOut (state) {
    window.sessionStorage.removeItem('user')
    state.authorized = false
    state.userModel = null
    state.userRole = '0000000'
    state.requireChangePassword = false
  },
  refreshedToken (state, token) {
    state.userModel.Token = token
    window.sessionStorage.user = JSON.stringify(state.userModel)
  },
  authed (state, str) {
    state.authorized = true
    state.userModel = JSON.parse(str)
    // state.userRole = ('00000' + state.userModel.Role.toString(2)).slice(-6)
    state.userRole = Utility.roleToBit(state.userModel.Role)
    if (state.userModel.ChangePassword) {
      state.requireChangePassword = true
    } else {
      state.requireChangePassword = false
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
