import { mapState, mapGetters, mapMutations } from 'vuex'
import { ja } from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import Validator from '@/utils/Validator'
// import Utility from '@/utils/Utility'

export default {
  name: 'NoticeInput',
  components: {
    Datepicker
  },
  data () {
    return {
      // 入力値
      subject: '',
      body: '',

      startDate: null,
      startHour: 0,
      startMinutes: 0,
      endDate: null,
      endHour: 0,
      endMinutes: 0,
      startDateOptions: {
        ja: ja,
        typeable: true,
        disabledDates: {
          to: null,
          from: null
        }
      },
      endDateOptions: {
        ja: ja,
        typeable: true,
        disabledDates: {
          to: null,
          from: null
        }
      },

      // flag
      invalidFlag: false,
      isClickedConfirm: false,
      // // wysiwig
      // wysiwygOptions: {
      //   hideModules: {
      //     bold: false,
      //     italic: false,
      //     underline: false,
      //     justifyLeft: false,
      //     justifyCenter: false,
      //     justifyRight: false,
      //     headings: true,
      //     code: true,
      //     orderedList: false,
      //     unorderedList: false,
      //     image: true,
      //     table: true,
      //     removeFormat: true,
      //     separator: true
      //   },
      //   maxHeight: '500px'
      // }
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapState({
      label: state => state.label.model,
      values: state => state.noticeForm.values
    }),
    ...mapGetters({
      isRegister: 'noticeForm/isRegister',
      isModify: 'noticeForm/isModify'
    }),

    // list
    getStartHours () {
      let list = []
      for (let i = 0, l = 24; i < l; i++) {
        list.push({
          value: i,
          label: i < 10 ? '0' + String(i) : String(i)
        })
      }
      return list
    },
    getStartMinutes () {
      let list = []
      for (let i = 0, l = 12; i < l; i++) {
        list.push({
          value: i * 5,
          label: i * 5 < 10 ? '0' + String(i * 5) : String(i * 5)
        })
      }
      return list
    },
    getEndHours () {
      let list = []
      for (let i = 0, l = 24; i < l; i++) {
        list.push({
          value: i,
          label: i < 10 ? '0' + String(i) : String(i)
        })
      }
      return list
    },
    getEndMinutes () {
      let list = []
      for (let i = 0, l = 12; i < l; i++) {
        list.push({
          value: i * 5,
          label: i * 5 < 10 ? '0' + String(i * 5) : String(i * 5)
        })
      }
      return list
    },
    disabledStartTime () {
      return this.startDate === null
    },
    disabledEndTime () {
      return this.endDate === null
    },

    // validate
    startDateIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validDate(this.startDate)
    },
    subjectIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.subject)
    },
    bodyIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.body)
    },
    formIsValid () {
      // return true
      return this.startDateIsValid &&
        this.subjectIsValid &&
        this.bodyIsValid
    },
    disableConfirmButton () {
      return !Validator.validDate(this.startDate) ||
        !Validator.validText(this.subject) ||
        !Validator.validText(this.body)
    }
  },
  methods: {
    ...mapMutations({
      setValues: 'noticeForm/setValues'
    }),
    init () {
      console.log(this.values)
      // 確認画面から戻った時、編集に宣した時に値を挿入
      // if (this.values) this.isClickedConfirm = true
      if (this.values.startDate) {
        this.startDate = this.values.startDate
        this.startHour = this.startDate.getHours()
        this.startMinutes = this.startDate.getMinutes()
        this.endDateOptions.disabledDates.to = moment(this.startDate).toDate()
      } else {
        this.startDate =  new Date()
        this.startHour = this.startDate.getHours()
        this.startMinutes = Math.floor(this.startDate.getMinutes() / 5) * 5
      }
      if (this.values.endDate) {
        this.endDate = this.values.endDate
        this.endHour = this.endDate.getHours()
        this.endMinutes = this.endDate.getMinutes()
        this.startDateOptions.disabledDates.from = moment(this.endDate).toDate()
      }
      if (this.values.subject) this.subject = this.values.subject
      if (this.values.body) this.body = this.values.body
      if (this.values.invalidFlag) this.invalidFlag = this.values.invalidFlag
    },
    changeStartDate (value) {
      if (value === null) {
        this.startDate = null
        return
      }
      let dateObj = new Date(value)
      this.startDate = new Date(dateObj.setHours(this.startHour, this.startMinutes, 0, 0))
      this.endDateOptions.disabledDates.to = moment(this.startDate).toDate()
    },
    changeEndDate (value) {
      if (value === null) {
        this.endDate = null
        return
      }
      let dateObj = new Date(value)
      this.endDate = new Date(dateObj.setHours(this.endHour, this.endMinutes, 0, 0))
      this.startDateOptions.disabledDates.from = moment(this.endDate).toDate()
    },
    changeStartTime () {
      this.startDate.setHours(this.startHour, this.startMinutes, 0, 0)
      this.endDateOptions.disabledDates.to = moment(this.startDate).toDate()
    },
    changeEndTime () {
      this.endDate.setHours(this.endHour, this.endMinutes, 0, 0)
      this.startDateOptions.disabledDates.from = moment(this.endDate).toDate()
    },
    changeBody (event) {
      // this.input.note = event.target.value.replace(/\n/, '<br>')
      this.body = event.target.value
    },
    clickConfirm () {
      this.isClickedConfirm = true
      // console.log(this.invalidFlag)
      // console.log(this.body)

      // validate
      if (this.formIsValid) {
        let data = $.extend(true, {}, this.values)
        data.startDate = this.startDate
        data.endDate = this.endDate
        data.subject = this.subject
        data.body = this.body
        if (this.isModify) data.invalidFlag = this.invalidFlag
        this.setValues(data)
        this.$router.push({name: 'NoticeConfirm'})
      }
    }
  }
}
