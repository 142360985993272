import { mapState, mapGetters, mapActions } from 'vuex'
import Loading from '@/components/Loading'
import Constants from '@/utils/Constants'
import AssetDetailModal from '@/components/Asset/AssetDetailModal'

export default {
  name: 'Asset',
  components: {
    Loading,
    AssetDetailModal
  },
  metaInfo () {
    this.setMeta({
      title: '橋梁データ一覧 | ' + Constants.SiteInfo.title
    })
    return this.getMeta
  },
  data () {
    return {
      showLoading: false,
      registerHash: Constants.Params.RegisterHash,
      // テーブルオプション
      tableColumns: [
        'LocationId',
        'Name',
        'CompletionYear',
        'RoadAdmin',
        'Address',
        'Prefecture',
        'City',
        'mapButton'
      ],
      tableData: [],
      tableOptions: {
        headings: {
          LocationId: '地域番号',
          Name: '橋梁名',
          CompletionYear: '施工年/月',
          RoadAdmin: '道路管理者',
          Address: '架橋場所',
          Prefecture: '都道府県',
          City: '市区町村',
          mapButton: ''
        },
        columnsClasses: {
          LocationId: 'text-center',
          Name: 'wp-12',
          CompletionYear: '',
          RoadAdmin: 'nw',
          Address: '',
          Prefecture: 'nw',
          City: 'wp-12 nw',
          mapButton: 'wp-8 text-center'
        },
        sortable: [
          'Name',
          'CompletionYear',
          'RoadAdmin',
          'Address',
          'Prefecture',
          'City'
        ],
        filterable: false,
        texts: Constants.TableOptions.getTexts('アセット'),
        perPage: Constants.TableOptions.perPage,
        perPageValues: Constants.TableOptions.perPageValues,
        pagination: Constants.TableOptions.pagination,
        resizableColumns: false,
      },
      // 検索
      nameQuery: '',
      prefectureQuery: '',
      cityQuery: '',
      // 絞込み
      showAll: 'true',
      // // 地図モーダルの緯度経度
      // latitude: null,
      // longitude: null
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapState({
      assetModel: state => state.asset.assetModel
    }),
    ...mapGetters({
      getMeta: 'meta/get'
    }),
    showResult () {
      return !this.showLoading && this.tableData.length > 0
    },

    // item list
    prefectureList () {
      let list = []
      for (let i = 0, l = Object.keys(Constants.Prefecture).length; i < l; i++) {
        let index = i + 1
        let key = index < 10 ? '0' + index : String(index)
        list.push({
          id: key,
          label: Constants.Prefecture[key]
        })
      }
      return list
    },

    // button
    disabledSearchName () {
      return this.nameQuery === ''
    },
    disabledSearchPrefecture () {
      return this.prefectureQuery === ''
    },
    disabledSearchCity () {
      return this.cityQuery === ''
    }
  },
  methods: {
    ...mapActions({
      setMeta: 'meta/set',
      loadAssetList: 'asset/loadAssetList',
      showError: 'error/show',
      initModal: 'assetDetailModal/init'
    }),
    init () {
      // データ読出し
      this.showLoading = true
      this.loadAssetList({
        showAll: this.showAll === 'true'
      })
        .then(() => {
          this.tableData = $.extend(this.assetModel, [], true)
          this.showLoading = false
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
        })
    },
    getViewData (props) {
      let key = props.column
      let value = props.row[key]

      if (key === 'CompletionYear') return props.row.CompletionMonth ?
        value + '/' + props.row.CompletionMonth : value
      if (key === 'Prefecture') return Constants.Prefecture[value]
      return value
    },
    changePage () {
      // console.log(this.$refs.table.page)
    },
    changeFilters () {
      // console.log('showAll : ' + this.showAll + ' / assetTypeId : ' + this.assetTypeId)
      let parameters = {}
      if (this.showAll !== null) parameters.showAll = this.showAll === 'true'
      if (this.nameQuery !== '') parameters.bridgeName = this.nameQuery
      if (this.prefectureQuery !== '') parameters.prefecture = this.prefectureQuery
      if (this.cityQuery !== '') parameters.city = this.cityQuery

      // アセット一覧を再読出し
      this.showLoading = true
      this.loadAssetList(parameters)
        .then(() => {
          this.tableData = $.extend(this.assetModel, [], true)
          this.showLoading = false
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
        })
    },

    
    /**
     * 絞り込み
     */
    searchName () {
      this.prefectureQuery = ''
      this.cityQuery = ''
      this.changeFilters()
    },
    searchPrefecture () {
      this.nameQuery = ''
      this.cityQuery = ''
      this.changeFilters()
    },
    searchCity () {
      this.nameQuery = ''
      this.prefectureQuery = ''
      this.changeFilters()
    },

    /**
     * 橋梁詳細モーダルを表示
     */
    clickDetail (props) {
      // モデルをstoreに保持
      this.initModal(props.row)
      
      // モダールを表示
      $('#modal-view-gmap').modal('show')
    },

    /**
     * 橋梁詳細モーダルを表示
     */
    downloadCsv () {
      // header
      // let header = '\ufeff'
      let header = '"地域番号",'
      header += '"道路管理者",'
      header += '"道路管理者区分",'
      header += '"事業所名",'
      header += '"連絡先",'
      header += '"路線名",'
      header += '"住所",'
      header += '"都道府県",'
      header += '"市町村",'

      header += '"北緯  ",'
      header += '"",'
      header += '"",'
      header += '"東経",' 
      header += '"",'
      header += '"",'

      header += '"橋梁名",'
      header += '"竣工年",'
      header += '"竣工月",'
      header += '"施工会社名",'
      header += '"橋梁管理者",'

      header += '"形式",'
      header += '"形式①",'
      header += '"形式②",'
      header += '"形式③",'
      header += '"備考",'

      header += '"橋長（m）",'
      header += '"支間長（m）",'
      header += '"有効幅員（m）",'
      header += '"落橋防止システム（新・旧）",'
      header += '\n'

      // フィルターデータはページに表示されて件数しか取得できない
      // console.log(this.$refs.table.filteredData)

      let getItem = function (value, isFirst) {
        console.log(value)
        let str = isFirst ? '' : ','
        if (value === null || value === '' || value === undefined) {
          return str + '""'
        }

        // eslint-disable-next-line
        str += ('"' + value.split('"').join('\"') + '"')
        return str
      }

      // body
      let body = ''
      this.assetModel.forEach(item => {
        let str = ''
        str += getItem(item.LocationId, true)
        str += getItem(item.RoadAdmin)
        str += getItem(item.RoadAdminKbn.toString())
        str += getItem(item.OfficeName)
        str += getItem(item.OfficeContact)
        str += getItem(item.LineName)
        str += getItem(item.Address)
        str += getItem(Constants.Prefecture[item.Prefecture])
        str += getItem(item.City)

        str += (',' + item.Latitude_60_degree + '°')
        str += (',' + item.Latitude_60_minutes + '\'')
        str += (',' + item.Latitude_60_seconds + '"')
        str += (',' + item.Longitude_60_degree + '°')
        str += (',' + item.Longitude_60_minutes + '\'')
        str += (',' + item.Longitude_60_seconds + '"')

        str += getItem(item.Name)
        str += getItem(item.CompletionYear)
        str += getItem(item.CompletionMonth)
        str += getItem(item.Constructor)
        str += getItem(item.BridgeAdmin)

        str += getItem(item.BridgeForm)
        str += getItem(item.Form1)
        str += getItem(item.Form2)
        str += getItem(item.Form3)
        str += getItem(item.Note)

        str += getItem(item.Length)
        str += getItem(item.Width)
        str += getItem(item.Spanner)
        str += getItem(Constants.AssetConst.FallPrevention[item.FallPreventionEq])
        str += '\n'
        body += str
      })
      // console.log(body)
      // let content = body
      let content = header + body

      // リンクを生成
      let bom = new Uint8Array([0xEF, 0xBB, 0xBF])
      let blob = new Blob([bom, content], {type: 'text/csv;charset=utf-8;'})
      let filename = 'assets.csv'
      let anchor = window.document.createElement('a')

      // ブラウザからダウンロード
      anchor.href = window.URL.createObjectURL(blob)
      anchor.download = filename
      window.document.body.appendChild(anchor)
      anchor.click()
      anchor.parentNode.removeChild(anchor)

      // // ie
      // if (window.navigator.msSaveBlob) {
      //   window.navigator.msSaveBlob(blob, filename)
      // // firefox / chrome
      // } else if (window.URL && anchor.download !== undefined) {
      //   anchor.href = window.URL.createObjectURL(blob)
      //   anchor.download = filename
      //   window.document.body.appendChild(anchor)
      //   anchor.click()
      //   anchor.parentNode.removeChild(anchor)
      // // safari
      // } else {
      //   window.location.href = 'data:attachment/csv;charset=utf-8,' + encodeURIComponent(bom + content);
      // }
    }
  }
}