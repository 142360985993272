import { mapGetters, mapActions } from 'vuex'
import Constants from '@/utils/Constants'
export default {
  name: 'Password',
  metaInfo () {
    this.setMeta({
      title: 'パスワード変更 | ' + Constants.SiteInfo.title
    })
    return this.getMeta
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapGetters({
      getMeta: 'meta/get'
    })
  },
  methods: {
    ...mapActions({
      setMeta: 'meta/set'
    }),
    init () {

    }
  }
}
