import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Utility from '@/utils/Utility'
import Vue from 'vue'

const state = {
  userModel: []
}

const getters = {
  get: state => state
}

const actions = {
  /**
   * ユーザー一覧を読出し
   */
  loadUserList ({commit}, {showAll, role}) {
    return new Promise((resolve, reject) => {
      let parameters = {}
      if (showAll !== undefined) parameters.showAll = showAll
      if (role !== undefined) parameters.roles = role

      ApiManager.request(
        ['user'],
        ['v1UserListGet'],
        [parameters])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            commit('setUserModel', res[0].data.Users)
            console.log('userModel', state.userModel)
            resolve()

          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {
  setUserModel (state, data) {
    data.sort(Utility.sortByKey('CreatedAt', false))
    Vue.set(state, 'userModel', data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
