import { mapState } from 'vuex'
import Constants from '@/utils/Constants'

export default {
  name: 'AssetDetailModal',
  // props: {
  //   latitude: Number,
  //   longitude: Number
  // },
  mounted () {
    // モーダル非表示時の処理
    $('#' + this.id).on('hide.bs.modal', () => {
      // storeをリセット？
    })
  },
  data () {
    return {
      mapZoom: 13
    }
  },
  computed: {
    ...mapState({
      model: state => state.assetDetailModal.model
    }),
    showContent () {
      return this.model !== null
    },
    getTitle () {
      console.log(this.model)
      if (this.model === null) return ''
      return this.model.Name + '（' + this.model.AssetId + '）'
    },

    // 左列
    locationId () {
      return this.model.LocationId || ''
    },
    completeDate () {
      if (this.model.CompletionYear && this.model.CompletionMonth) {
        return this.model.CompletionYear + ' / ' + this.model.CompletionMonth
      } else {
        return this.model.CompletionYear || ''
      }
    },
    roadAdmin () {
      return this.model.RoadAdmin || ''
    },
    officeName () {
      return this.model.OfficeName || ''
    },
    officeContact () {
      return this.model.OfficeContact || ''
    },
    lineName () {
      return this.model.LineName || ''
    },

    // 中列
    prefecture () {
      return this.model.Prefecture ? Constants.Prefecture[this.model.Prefecture] : ''
    },
    city () {
      return this.model.City || ''
    },
    address () {
      return this.model.Address || ''
    },
    latitude () {
      if (this.model.Latitude_60_degree === undefined ||
        this.model.Latitude_60_minutes === undefined ||
        this.model.Latitude_60_seconds === undefined) return ''
      return this.model.Latitude_60_degree + '° ' +
        this.model.Latitude_60_minutes + '&#39;' +
        this.model.Latitude_60_seconds + '&quot;'
    },
    longitude () {
      if (this.model.Longitude_60_degree === undefined ||
        this.model.Longitude_60_minutes === undefined ||
        this.model.Longitude_60_seconds === undefined) return ''
      return this.model.Longitude_60_degree + '° ' +
        this.model.Longitude_60_minutes + '&#39;' +
        this.model.Longitude_60_seconds + '&quot;'
    },
    constructorName () {
      return this.model.Constructor || ''
    },

    // 右列
    bridgeAdmin () {
      return this.model.BridgeAdmin || ''
    },
    bridgeForm () {
      return this.model.BridgeForm || ''
    },
    bridgeLength () {
      return this.model.Length ? this.model.Length + ' (m)' : ''
    },
    bridgeSpanner () {
      return this.model.Spanner ? this.model.Spanner + ' (m)' : ''
    },
    bridgeWidth () {
      return this.model.Width ? this.model.Width + ' (m)' : ''
    },
    fallPrevention () {
      if (this.model.FallPreventionEq === undefined) return ''
      return Constants.AssetConst.FallPrevention[this.model.FallPreventionEq]
    },

    // map
    showMap () {
      return this.model.Latitude && this.model.Longitude
    },
    lat () {
      return this.model.Latitude
    },
    lng () {
      return this.model.Longitude
    },
    mapCenter () {
      return {
        lat: this.lat,
        lng: this.lng
      }
    }
  }
}