export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      User: {
        UserId: 10,
        UserIdentity: '1b2c6a69f00ab0ea7583ce9b3c58b4a7',
        Token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9kZXYuaW5zcGVjdGlvbi5qaXcuY28uanBcL2FwaVwvdjFcL2xvZ2luXC91c2VyIiwiaWF0IjoxNTgzNzMyNTY1LCJleHAiOjE1ODM4MTg5NjUsIm5iZiI6MTU4MzczMjU2NSwianRpIjoiYTdYeFAxeHRUUG5OY1RRWiIsInN1YiI6MjQsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.-Z_Gp2L1P8gePjqtOrYISFC0M0cp-dYL5ibCeBI7ZXk',
        TenantId: 10,
        TenantIdentity: '36ab6155cb5623d4565ec58f48464c62',
        TenantName: '株式会社XXXXXXXX',
        Role: 2,
        // 1 : system administrator
        // 2 : tenant administrator
        // 4 : moderator
        // 8 : pilot
        // 16 : inspector
        // 32 : customer
        UserName: 'テナント管理 太郎',
        ChangePassword: false,
        TimeStamp: '1583732565',
        CheckStr: '229dd40e85c7e5071bfc2fcdd9b45225'
      }
    }
  }
}