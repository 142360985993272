import { mapState, mapGetters } from 'vuex'

export default {
  name: 'Aside',
  computed: {
    ...mapState({
      authorized: state => state.auth.authorized,
      requireChangePassword: state => state.auth.requireChangePassword
    }),
    ...mapGetters({
      privateRelease: 'config/privateRelease',
      publicRelease: 'config/publicRelease',
      isSystemAdmin: 'auth/isSystemAdmin',
      isTenantAdmin: 'auth/isTenantAdmin'
    }),
    hideAside () {
      return !this.authorized || this.requireChangePassword
    }
  },
  methods: {
    isActive (category) {
      if (!this.$route.name) return false

      let pathArray = this.$route.path.split('/')
      if(pathArray[0] === '') pathArray.shift()
      if(pathArray[pathArray.length - 1] === '') pathArray.pop()
      // console.log('isActive | ', pathArray + ' : ' +  this.$route.name + ' : ' + category)
    
      // お知らせ管理
      if (category === 'Notice') {
        return pathArray[0] === 'notice'
      }
      // テナント管理
      if (category === 'Tenant') {
        return pathArray[0] === 'tenant'
      }
      // 橋梁管理
      if (category === 'Asset') {
        return pathArray[0] === 'asset'
      }
      // テナント管理者管理
      if (category === 'TenantAdmin') {
        return pathArray[0] === 'tenantAdmin'
      }
      // 管理者管理
      if (category === 'Moderator') {
        return pathArray[0] === 'moderator'
      }
      // 点検者管理
      if (category === 'Inspector') {
        return pathArray[0] === 'inspector'
      }
      // プロフィール編集
      if (category === 'Account') {
        return pathArray[0] === 'account'
      }
    }
  }
}