import { mapState, mapActions } from 'vuex'
export default {
  name: 'Alert',
  props: {
    id: String
  },
  mounted () {
    // // モーダル表示時の処理
    // $('#' + this.id).on('show.bs.modal', () => {

    // })

    // モーダル非表示時の処理
    $('#' + this.id).on('hide.bs.modal', () => {
      this.hide()
    })
  },
  data () {
    return {
      defaultTitle: '',
      defaultBody: '',
      defaultLabel: '閉じる'
    }
  },
  computed: {
    ...mapState({
      active: state => state.alert.active,
      title: state => state.alert.title,
      body: state => state.alert.body,
      label: state => state.alert.label
    }),
    getTitle () {
      return this.title || this.defaultTitle
    },
    getBody () {
      return this.body || this.defaultBody
    },
    getAcceptLabel () {
      return this.label || this.defaultLabel
    }
  },
  watch: {
    '$route' () {
      // console.log('close modal')
      $('#' + this.id).modal('hide')
    },
    active (to) {
      if (to) {
        // console.log('open modal')
        $('#' + this.id).modal('show')
      } else {
        // console.log('close modal')
        $('#' + this.id).modal('hide')
      }
    }
  },
  methods: {
    ...mapActions({
      hide: 'alert/hide'
    }),
    clickAccept () {
      this.$emit('childs-event', {
        accept: true
      })
      $('#' + this.id).modal('hide')
    }
  }
}