// import Constants from '@/utils/Constants'

const state = {
	test: 'Test'
}

const getters = {
  get: (state) => {
    return state
  }
}

const actions = {}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
