import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import Constants from '@/utils/Constants'
import Validator from '@/utils/Validator'

export default {
  name: 'AssetInput',
  data () {
    return {
      // 市区町村リスト
      cityList: [],

      // 入力値
      locationId: '',
      name: '',
      completionYear: '',
      completionMonth: '',
      roadAdmin: '',
      roadAdminKbn: 0,
      officeName: '',
      officeContact: '',
      lineName: '',
      prefecture: '',
      city: '',
      address: '',

      latitude_60_degree: '',
      latitude_60_minutes: '',
      latitude_60_seconds: '',
      longitude_60_degree: '',
      longitude_60_minutes: '',
      longitude_60_seconds: '',

      constructorName: '',
      bridgeAdmin: '',
      bridgeForm: '',
      form1: '',
      form2: '',
      form3: '',
      length: '',
      spanner: '',
      width: '',
      fallPreventionEq: null,

      figure1: null,
      file: null,

      note: '',
      invalidFlag: false,
      isClickedConfirm: false
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapState({
      assetId: state => state.assetForm.targetId,
      values: state => state.assetForm.values
    }),
    ...mapGetters({
      isRegister: 'assetForm/isRegister',
      isModify: 'assetForm/isModify'
    }),

    // result
    assetIdResult () {
      return this.assetId === '-' ? '' : this.assetId
    },

    // item list
    roadAdminPeriodList () {
      let list = []
      Object.keys(Constants.AssetConst.RoadAdminPeriod)
        .forEach(item => list.push({
          value: item,
          label: Constants.AssetConst.RoadAdminPeriod[item]
        }))
      return list
    },
    prefectureList () {
      let list = []
      for (let i = 0, l = Object.keys(Constants.Prefecture).length; i < l; i++) {
        let index = i + 1
        let key = index < 10 ? '0' + index : String(index)
        list.push({
          id: key,
          label: Constants.Prefecture[key]
        })
      }
      return list
    },
    fallPreventionEqList () {
      let list = [{
        value: '',
        label: ''
      }]
      Object.keys(Constants.AssetConst.FallPrevention).forEach(item => {
        list.push({
          value: Number(item),
          label: Constants.AssetConst.FallPrevention[item]
        })
      })
      return list
    },

    // validate
    roadAdminKbnIsValid () {
      if (!this.isClickedConfirm) return true
      return this.roadAdminKbn > 0
    },
    nameIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.name)
    },
    prefectureIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.prefecture)
    },
    cityIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.city)
    },

    latitudeIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.latitude_60_degree) &&
        Validator.validText(this.latitude_60_minutes) &&
        Validator.validText(this.latitude_60_seconds)
    },
    longitudeIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.longitude_60_degree) &&
        Validator.validText(this.longitude_60_minutes) &&
        Validator.validText(this.longitude_60_seconds)
    },

    noteIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.note)
    },
    formIsValid () {
      // return true
      return this.roadAdminKbnIsValid &&
        this.nameIsValid &&
        this.prefectureIsValid &&
        this.cityIsValid &&
        this.latitudeIsValid &&
        this.longitudeIsValid
    },

    // button
    disableConfirmButton () {
      return this.roadAdminKbn === 0 ||
        !Validator.validText(this.name) ||
        !Validator.validText(this.prefecture) ||
        !Validator.validText(this.city) ||
        !Validator.validText(this.latitude_60_degree) ||
        !Validator.validText(this.latitude_60_minutes) ||
        !Validator.validText(this.latitude_60_seconds) ||
        !Validator.validText(this.longitude_60_degree) ||
        !Validator.validText(this.longitude_60_minutes) ||
        !Validator.validText(this.longitude_60_seconds)
    }
  },
  watch: {
    prefecture (to) {
      this.cityList = []

      // 都道府県名を取得
      let label = this.prefectureList.filter(item => item.id === to)[0].label
      console.log(label)

      // 市区町村リストを読み出し
      this.load1GovermentCodes({
        prefectureName: label
      })
        .then(res => {
          if (res && res.length) this.cityList = res
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
        })
    }
  },
  methods: {
    ...mapActions({
      showAlert: 'alert/show',
      load1GovermentCodes: 'assetForm/load1GovermentCodes'
    }),
    ...mapMutations({
      setValues: 'assetForm/setValues'
    }),
    init () {
      // 確認画面から戻った時、編集に宣した時に値を挿入
      // if (this.values) this.isClickedConfirm = true
      if (this.values.roadAdminKbn) this.roadAdminKbn = this.values.roadAdminKbn
      if (this.values.locationId) this.locationId = this.values.locationId
      if (this.values.name) this.name = this.values.name
      if (this.values.completionYear) this.completionYear = this.values.completionYear
      if (this.values.completionMonth) this.completionMonth = this.values.completionMonth
      if (this.values.roadAdmin) this.roadAdmin = this.values.roadAdmin
      if (this.values.officeName) this.officeName = this.values.officeName
      if (this.values.officeContact) this.officeContact = this.values.officeContact
      if (this.values.lineName) this.lineName = this.values.lineName
      if (this.values.prefecture) this.prefecture = this.values.prefecture
      if (this.values.city) this.city = this.values.city
      if (this.values.address) this.address = this.values.address

      if (this.values.latitude_60_degree !== undefined) this.latitude_60_degree = this.values.latitude_60_degree
      if (this.values.latitude_60_minutes !== undefined) this.latitude_60_minutes = this.values.latitude_60_minutes
      if (this.values.latitude_60_seconds !== undefined) this.latitude_60_seconds = this.values.latitude_60_seconds
      if (this.values.longitude_60_degree !== undefined) this.longitude_60_degree = this.values.longitude_60_degree
      if (this.values.longitude_60_minutes !== undefined) this.longitude_60_minutes = this.values.longitude_60_minutes
      if (this.values.longitude_60_seconds !== undefined) this.longitude_60_seconds = this.values.longitude_60_seconds

      if (this.values.constructorName) this.constructorName = this.values.constructorName
      if (this.values.bridgeAdmin) this.bridgeAdmin = this.values.bridgeAdmin
      if (this.values.bridgeForm) this.bridgeForm = this.values.bridgeForm
      if (this.values.form1) this.form1 = this.values.form1
      if (this.values.form2) this.form2 = this.values.form2
      if (this.values.form3) this.form3 = this.values.form3
      if (this.values.length) this.length = this.values.length
      if (this.values.spanner) this.spanner = this.values.spanner
      if (this.values.width) this.width = this.values.width
      if (this.values.fallPreventionEq) this.fallPreventionEq = this.values.fallPreventionEq
      if (this.values.figure1) this.figure1 = this.values.figure1

      if (this.values.note) this.note = this.values.note
      if (this.values.invalidFlag) this.invalidFlag = this.values.invalidFlag

      // this.locationId = '13'
      // this.completionYear = '2000'
      // this.completionMonth = '01'
      // this.roadAdmin = '道路管理株式会社'
      // this.officeName = '管理部'
      // this.officeContact = '000-000-0000'
      // this.constructorName = 'test建設'
      // this.bridgeAdmin = '管理タロウ'
      // this.bridgeForm = '形式A'
      // this.form1 = '形式Aa'
      // this.length = '120'
      // this.spanner = '10'
      // this.width = '13'
      // this.latitude_60_seconds = '00'
      // this.longitude_60_seconds = '00'
    },
    /**
     * 写真登録
     */
    changeFile (event) {
      // console.log('File', event.target.files[0])
      let file = event.target.files[0]
      
      // 拡張子チェック
      let tmp = file.name.split('.')
      let ext = tmp[tmp.length - 1].toLowerCase()
      // 拡張子エラーが有った場合
      if (Constants.Extensions.document.indexOf(ext) === -1) {
        this.$refs['file'].value = '';
        this.showAlert({
          body: '許可されていない拡張子のファイルはアップロードできません。<br>「' +
            file.name +
            '」'
        })
        return
      }

      this.file = file
    },
    clickConfirm () {
      this.isClickedConfirm = true
      // console.log(this.invalidFlag)

      // validate
      if (this.formIsValid) {
        let data = $.extend(true, {}, this.values)
        data.locationId = this.locationId
        data.name = this.name
        data.completionYear = this.completionYear
        data.completionMonth = this.completionMonth
        data.roadAdmin = this.roadAdmin
        data.roadAdminKbn = this.roadAdminKbn
        data.officeName = this.officeName
        data.officeContact = this.officeContact
        data.lineName = this.lineName
        data.prefecture = this.prefecture
        data.city = this.city
        data.address = this.address

        data.latitude_60_degree = this.latitude_60_degree
        data.latitude_60_minutes = this.latitude_60_minutes
        data.latitude_60_seconds = this.latitude_60_seconds
        data.longitude_60_degree = this.longitude_60_degree
        data.longitude_60_minutes = this.longitude_60_minutes
        data.longitude_60_seconds = this.longitude_60_seconds
        
        data.constructorName = this.constructorName
        data.bridgeAdmin = this.bridgeAdmin
        data.bridgeForm = this.bridgeForm
        data.form1 = this.form1
        data.form2 = this.form2
        data.form3 = this.form3
        data.length = this.length
        data.spanner = this.spanner
        data.width = this.width
        data.fallPreventionEq = this.fallPreventionEq
        data.file = this.file

        data.note = this.note
        if (this.isModify) data.invalidFlag = this.invalidFlag
        this.setValues(data)

        console.log(data)
        this.$router.push({name: 'AssetConfirm'})
      }
    }
  }
}
