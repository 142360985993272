import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Utility from '@/utils/Utility'
import Vue from 'vue'

const state = {
  userModel: [],

  // 各種クエリ
  companyQuery: '',
  nameQuery: '',

  // 検索クエリ
  serachedType: null,
  searchedQuery: '',
}

const getters = {
  get: state => state
}

const actions = {
  /**
   * ユーザー一覧を読出し
   */
  loadUserList ({commit}, {showAll, type}) {
    /*
     * 検索クエリ以外の入力をリセット
     */
    let clearQueries = type => {
      let queries = [
        'companyQuery',
        'nameQuery'
      ]
      queries.forEach((item, index) => {
        if (type !== index) Vue.set(state, item, '')
      })
    }

    // 検索条件
    let value = {
      type: type === -1 ? null : type,
      query: ''
    }
    if (type === -1) value.query = state.searchedQuery
    if (type === 0) value.query = state.companyQuery
    if (type === 1) value.query = state.nameQuery
      console.log('value', value)
    commit('setSearchedValue', value)

    return new Promise((resolve, reject) => {
      // 点検者一覧を読出し
      let parameters = {}
      if (showAll !== undefined) parameters.showAll = showAll
      if (type === 0) parameters.company = state.companyQuery
      if (type === 1) parameters.name = state.nameQuery

      // 検索クエリ以外の入力をリセット
      if (type !== -1) {
        clearQueries(type)
      }

      ApiManager.request(
        ['user'],
        ['v1InspectorListGet'],
        [parameters])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            // commit('setUserModel', res)
            commit('setUserModel', res[0].data.Users)
            console.log('userModel', state.userModel)
            resolve()

          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // loadUserList ({getters, commit}, {showAll}) {
  //   return new Promise((resolve, reject) => {
  //     // ユーザー一覧を読出し
  //     let parameters = {
  //       roles: 'inspector'
  //     }
  //     if (showAll !== undefined) parameters.showAll = showAll

  //     ApiManager.request(
  //       ['user'],
  //       ['v1UserListGet'],
  //       [parameters])
  //       .then(res => {
  //         if (res[0].data.Result === Constants.ApiResult.Success) {
  //           // commit('setUserModel', res)
  //           commit('setUserModel', res[0].data.Users)
  //           console.log('userModel', state.userModel)
  //           resolve()

  //         } else {
  //           reject(res[0].data)
  //         }
  //       })
  //       .catch(error => {
  //         reject(error)
  //       })
  //   })
  // }
}

const mutations = {
  setUserModel (state, data) {
    data.sort(Utility.sortByKey('CreatedAt', false))
    Vue.set(state, 'userModel', data)
  },
  // 各種クエリ
  setCompanyQuery (state, str) {
    state.companyQuery = str
  },
  setNameQuery (state, str) {
    state.nameQuery = str
  },
  setSearchedValue (state, {type, query}) {
    state.serachedType = type
    state.searchedQuery = query
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
