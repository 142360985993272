export default {
  name: 'DndZone',
  props: {
    params: Object
  },
  data () {
    return {
      isDrag: false
    }
  },
  methods: {
    checkDrag(event, status){
      //ファイルではなく、html要素をドラッグしてきた時は処理を中止
      if (status && event.dataTransfer.types == "text/plain") return
      this.isDrag = status || false
    },
    //inputタグとドラッグ&ドロップから呼ばれる
    onDrop (event) {
      this.isDrag = false

      // ファイル取得
      let fileList = event.target.files ? event.target.files : event.dataTransfer.files
      if(fileList.length == 0) return
      console.log(fileList)

      // let files = [];
      // for(let i = 0; i < fileList.length; i++){
      //   files.push(fileList[i])
      // }

      // // 今回は1ファイルのみ送ることにする。
      // let file = files.length > 0 ? files[0] : []
      // let fd = new FormData()
      // fd.append('file', file)

      let params = $.extend(this.params, {fileList: fileList}, true)
      this.$emit('childs-event', params)

      // this.$root.ajax('post', url, fd, (data) => {
      //   // 同じファイル名を選択した際にchangeイベントが走るようにvalueを初期化する。//★③
      //   event.target.value = null;
      //   // あとは、コールバック処理に色々書く。
      // });
    },
    reset (event) {
      event.target.value = '';
    }
  }
}