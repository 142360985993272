export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      Tenants: [
        {
          TenantId: 1,
          TenantIdentity: '36ab6155cb5623d4565ec58f48464c62',
          TenantName: '株式会社XXXXXXXX',
          TenantNameKana: 'カブシキカイシャXXXXXXXX',
          TenantAddress: '東京都中央区',
          TenantAddressKana: 'トウキョウトチュウオウク',
          LiveFlg: true,
          TantouDepartment: '担当部署',
          TantouName: '担当者名',
          TantouNameKana: 'タントウシャメイ',
          TantouEmail: 'aaa@aaa.com',
          TantouPhone1: '03',
          TantouPhone2: '3333',
          TantouPhone3: '6666',
          TantouAddress: '東京都渋谷区',
          Note: '備考\n備考',
          CreatedAt: '2019-08-02',
          UpdatedAt: '2019-09-20',
          EventCount: 3,
          ProjectCount: 11
        }
      ]
    }
  }
}