import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Vue from 'vue'

const state = {
  targetId: Constants.Params.RegisterHash,
  values: {}
}

const getters = {
  get: state => state,
  isRegister: state => state.targetId === Constants.Params.RegisterHash,
  isModify: state => state.targetId !== Constants.Params.RegisterHash
}

const actions = {
  /**
   * 初期読出し
   */
  initLoad ({commit}, {targetId}) {
    commit('setTargetId', targetId)

    return new Promise((resolve, reject) => {
      if (getters.isRegister(state)) {
        resolve()

      } else {
        // アセット詳細を読出し
        ApiManager.request(
          ['asset'],
          ['v1AssetDetailGet'],
          [{ assetIds: [state.targetId] }])
          .then(res => {
            if (res[0].data.Result === Constants.ApiResult.Success) {
              commit('initLoaded', res[0].data.Assets[0])
              resolve()
              
            } else {
              reject(res[0].data)
            }
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  },
  /**
   * 市区町村マスタ読出し
   */
  // eslint-disable-next-line
  load1GovermentCodes ({commit}, {prefectureName}) {
    return new Promise((resolve, reject) => {
      ApiManager.request(
        ['util'],
        ['v1GovermentCodesListGet'],
        [{
          prefectureName: prefectureName
        }])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            console.log('v1GovermentCodesListGet', res[0].data.LocalGovermentCodes)
            resolve(res[0].data.LocalGovermentCodes)
            
          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {
  initLoaded (state, data) {
    Vue.set(state, 'values', {
      locationId: data.LocationId,
      name: data.Name,
      completionYear: data.CompletionYear,
      completionMonth: data.CompletionMonth,
      roadName: data.RoadAdmin,
      officeName: data.OfficeName,
      officeContact: data.OfficeContact,
      lineName: data.LineName,
      prefecture: data.Prefecture,
      city: data.City,
      address: data.Address,
      latitude_60_degree: data.Latitude_60_degree,
      latitude_60_minutes: data.Latitude_60_minutes,
      latitude_60_seconds: data.Latitude_60_seconds,
      longitude_60_degree: data.Longitude_60_degree,
      longitude_60_minutes: data.Longitude_60_minutes,
      longitude_60_seconds: data.Longitude_60_seconds,
      constructorName: data.Constructor,
      bridgeAdmin: data.BridgeAdmin,
      bridgeForm: data.BridgeForm,
      roadAdmin: data.RoadAdmin,
      roadAdminKbn: data.RoadAdminKbn,
      form1: data.Form1,
      form2: data.Form2,
      form3: data.Form3,
      length: data.Length,
      spanner: data.Spanner,
      width: data.Width,
      fallPreventionEq: data.FallPreventionEq,
      figure1: data.Figure1,
      figure2: data.Figure2,
      ownerName: data.OwnerName,
      invalidFlag: !data.LiveFlg,
      note: data.Note
    })
  },
  setTargetId (state, targetId) {
    state.targetId = targetId
  },
  setValues (state, values) {
    Vue.set(state, 'values', values)
  },
  reset (state) {
    // state.targetId = Constants.Params.RegisterHash
    Vue.set(state, 'values', {})
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
