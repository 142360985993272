import { mapState, mapGetters, mapMutations } from 'vuex'
import Constants from '@/utils/Constants'
export default {
  name: 'InspectorComplete',
  data () {
    return {
      redirectTimer: null
    }
  },
  mounted () {
    this.resetForm()
    this.redirectTimer = setTimeout(() => {
      this.setResetPasswordFlag(false)
      this.$router.push({name: 'Inspector'})
    }, Constants.Params.CompletedRedirectDelay)
  },
  beforeDestroy () {
    if (this.redirectTimer) {
      clearTimeout(this.redirectTimer)
      this.redirectTimer = null
    }
  },
  computed: {
    ...mapState({
      resetPasswordFlag: state => state.inspectorForm.resetPasswordFlag
    }),
    ...mapGetters({
      isRegister: 'inspectorForm/isRegister',
      isModify: 'inspectorForm/isModify'
    }),
    description () {
      return this.isRegister ?
        '点検者を登録しました。' :
        this.resetPasswordFlag ? '点検者のパスワードを変更しました。' : '点検者を編集しました。'
    }
  },
  methods: {
    ...mapMutations({
      resetForm: 'inspectorForm/reset',
      setResetPasswordFlag: 'inspectorForm/setResetPasswordFlag'
    })
  }
}
