export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      Users: {
        TenantId: 1,
        TenantIdentity: 'xxxxx',
        UserId: 1,
        LoginId: 'customer01',
        UserRole: 1,
        UserIdentity: 'identifier01',
        UserName: '株式会社AAA',
        Abbreviation: 'AAA',
        Email: 'aaa@aaa.com',
        Mobile: '090-0000-0000',
        Contacts: 'contacts',
        LiveFlg: true,
        Note: '備考\n備考',
        CreatedDt: '2019-08-02',
        UpdatedDt: '2019-09-20'
      }
    }
  }
}