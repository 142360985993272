import { mapState, mapGetters, mapActions } from 'vuex'
import Loading from '@/components/Loading'
import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'

export default {
  name: 'InspectorConfirm',
  components: {
    Loading
  },
  data () {
    return {
      connecting: false
    }
  },
  computed: {
    ...mapState({
      userIdentity: state => state.inspectorForm.targetId,
      values: state => state.inspectorForm.values
    }),
    ...mapGetters({
      isSystemAdmin: 'auth/isSystemAdmin',
      isTenantAdmin: 'auth/isTenantAdmin',
      isRegister: 'inspectorForm/isRegister',
      isModify: 'inspectorForm/isModify',
      tenantIdentity: 'auth/tenantIdentity'
    }),
    roleLabel () {
      return Constants.UserRole.filter(item => item.code === this.values.userRole)[0].label
    },
    note () {
      return this.values.note ? this.values.note.replace(/\n/, '<br>') : ''
    },
    invalidFlag () {
      return this.values.invalidFlag ? '無効' : '有効'
    }
  },
  methods: {
    ...mapActions({
      showError: 'error/show'
    }),
    clickBack () {
      this.$router.go(-1)
    },
    clickRegister () {
      this.connecting = true

      let method = this.isRegister ? 'v1UserPost' : 'v1UserUpdate'
      let parameters = {
        UserData: {
          UserRole: this.values.userRole,
          InspectorCompanyName: this.values.company,
          InspectorCompanyNameKana: this.values.companyKana,
          InspectorLeaderName: this.values.leader,
          InspectorLeaderNameKana: this.values.leaderKana,
          Name: this.values.name,
          NameKana: this.values.kana,
          Division: this.values.division,
          Email: this.values.email,
          Phone1: this.values.phone1,
          Phone2: this.values.phone2,
          Phone3: this.values.phone3,
          Address: this.values.address,
          Note: this.values.note,
        }
      }
      if (this.isRegister) {
        parameters.UserData.UserTenantIdentity = this.tenantIdentity
        parameters.UserData.LoginId = this.values.loginId
      }
      if (this.isModify) {
        parameters.UserData.TargetUserIdentity = this.userIdentity
        parameters.UserData.LiveFlg = !this.values.invalidFlag
      }
      console.log('register data', parameters)
      // return

      // 登録
      ApiManager.request(['user'], [method], [parameters])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            this.$router.push({name: 'InspectorComplete'})

          // API エラー
          } else if (res[0].data.Result === Constants.ApiResult.Failure) {
            this.showError(res[0].data)
            
          // システムエラー
          } else {
            this.showError(null)
          }
          this.connecting = false
          
        })
        .catch(() => {
          this.showError(null)
          this.connecting = false
        })
    }
  }
}
