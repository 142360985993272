import { mapState, mapGetters, mapMutations } from 'vuex'
import Validator from '@/utils/Validator'
export default {
  name: 'TenantInput',
  data () {
    return {
      // 入力値
      name: '',
      kana: '',
      address: '',
      addressKana: '',
      division: '',
      person: '',
      personKana: '',
      email: '',
      phone1: '',
      phone2: '',
      phone3: '',
      contacts: '',
      note: '',
      invalidFlag: false,
      isClickedConfirm: false
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapState({
      label: state => state.label.model,
      values: state => state.tenantForm.values
    }),
    ...mapGetters({
      isRegister: 'tenantForm/isRegister',
      isModify: 'tenantForm/isModify'
    }),
    // validate
    nameIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.name)
    },
    kanaIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.kana)
    },
    addressIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.address)
    },
    addressKanaIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.addressKana)
    },
    personIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.person)
    },
    personKanaIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.personKana)
    },
    emailIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.email)
    },
    phoneIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.phone1) &&
        Validator.validText(this.phone2) &&
        Validator.validText(this.phone3)
    },
    noteIsValid () {
      if (!this.isClickedConfirm) return true
      return Validator.validText(this.note)
    },
    formIsValid () {
      // return true
      return this.nameIsValid &&
        this.kanaIsValid &&
        this.addressIsValid &&
        this.addressKanaIsValid &&
        this.personIsValid &&
        this.personKanaIsValid &&
        this.emailIsValid &&
        this.phoneIsValid
    },
    disableConfirmButton () {
      return !Validator.validText(this.name) ||
        !Validator.validText(this.kana) ||
        !Validator.validText(this.address) ||
        !Validator.validText(this.addressKana) ||
        !Validator.validText(this.person) ||
        !Validator.validText(this.personKana) ||
        !Validator.validText(this.email) ||
        !Validator.validText(this.phone1) ||
        !Validator.validText(this.phone2) ||
        !Validator.validText(this.phone3)
    }
  },
  methods: {
    ...mapMutations({
      setValues: 'tenantForm/setValues'
    }),
    init () {
      // 確認画面から戻った時、編集に宣した時に値を挿入
      // if (this.values) this.isClickedConfirm = true
      if (this.values.name) this.name = this.values.name
      if (this.values.kana) this.kana = this.values.kana
      if (this.values.address) this.address = this.values.address
      if (this.values.addressKana) this.addressKana = this.values.addressKana
      if (this.values.division) this.division = this.values.division
      if (this.values.person) this.person = this.values.person
      if (this.values.personKana) this.personKana = this.values.personKana
      if (this.values.email) this.email = this.values.email
      if (this.values.phone1) this.phone1 = this.values.phone1
      if (this.values.phone2) this.phone2 = this.values.phone2
      if (this.values.phone3) this.phone3 = this.values.phone3
      if (this.values.contacts) this.contacts = this.values.contacts
      if (this.values.note) this.note = this.values.note
      if (this.values.invalidFlag) this.invalidFlag = this.values.invalidFlag
    },
    changeNote (event) {
      // this.input.note = event.target.value.replace(/\n/, '<br>')
      this.note = event.target.value
    },
    clickConfirm () {
      this.isClickedConfirm = true

      // validate
      if (this.formIsValid) {
        let data = $.extend(true, {}, this.values)
        data.name = this.name
        data.kana = this.kana
        data.address = this.address
        data.addressKana = this.addressKana
        data.division = this.division
        data.person = this.person
        data.personKana = this.personKana
        data.email = this.email
        data.phone1 = this.phone1
        data.phone2 = this.phone2
        data.phone3 = this.phone3
        data.contacts = this.contacts
        data.note = this.note
        if (this.isModify) data.invalidFlag = this.invalidFlag
        this.setValues(data)
        this.$router.push({name: 'TenantConfirm'})
      }
    }
  }
}
