export default {
  // rev
  // 0 : 3月リリース
  // 1 : 本公開
  Revision: 1,
  
  // GoogleMap API Key for Prd
  GoogleMapApiKey: process.env.VUE_APP_GOOGLEMAP_API_KEY,
  GoogleMapCenter: {
    lat: 35.6432027,
    lng: 139.6729435
  },
  
  // meta
  Meta: [
    {'http-equiv': 'X-UA-Compatible', content: 'IE=edge'},
    {name: 'viewport', content: 'width=device-width,initial-scale=1.0'}
  ],
  // サイト情報
  SiteInfo: {
    title: '橋梁点検データベースシステム'
  },
  // パラメータ
  Params: {
    RegisterHash: '-',
    CompletedRedirectDelay: 1500
  },
  // テーブルオプション
  TableOptions: {
    perPage: 20,
    perPageValues: [],
    // perPageValues: [20, 50],
    pagination: {
      edge: false,
      chunk: 10
    },
    getTexts (label) {
      return {
        count: '{count}件中{from}～{to}件を表示|{count}件|1件',
        noResults: label + 'はありません。',
        limit: '表示件数',
        page: 'ページ'
      }
    }
  },
  // ユーザー権限
  UserRole: [
    {
      code: 'systemAdmin',
      abbrevation: 'S',
      label: 'システム管理者'
    },
    {
      code: 'tenantAdmin',
      abbrevation: 'T',
      label: 'テナント管理者'
    },
    {
      code: 'moderator',
      abbrevation: 'M',
      label: '管理者'
    },
    {
      code: 'pilot',
      abbrevation: 'P',
      label: 'パイロット'
    },
    {
      code: 'inspector',
      abbrevation: 'I',
      label: '点検者'
    },
    {
      code: 'customer',
      abbrevation: 'C',
      label: 'カスタマー'
    },
    {
      code: 'observer',
      abbrevation: 'O',
      label: '管理者（閲覧のみ）'
    }
  ],

  // 橋梁のステータス
  AssetConst: {
    FallPrevention: {
      '0' : 'なし',
      '1' : '旧',
      '2' : '新'
    },
    RoadAdminPeriod: {
      '1': '国',
      '2': '都道府県',
      '3': '市町村',
      '4': 'その他'
    }
  },
  
  // 総務省制定の都道府県番号
  Prefecture: {
    '01': '北海道',
    '02': '青森県',
    '03': '秋田県',
    '04': '宮城県',
    '05': '秋田県',
    '06': '山形県',
    '07': '福島県',
    '08': '茨城県',
    '09': '栃木県',
    '10': '群馬県',
    '11': '埼玉県',
    '12': '千葉県',
    '13': '東京都',
    '14': '神奈川県',
    '15': '新潟県',
    '16': '富山県',
    '17': '石川県',
    '18': '福井県',
    '19': '山梨県',
    '20': '長野県',
    '21': '岐阜県',
    '22': '静岡県',
    '23': '愛知県',
    '24': '三重県',
    '25': '滋賀県',
    '26': '京都府',
    '27': '大阪府',
    '28': '兵庫県',
    '29': '奈良県',
    '30': '和歌山県',
    '31': '鳥取県',
    '32': '島根県',
    '33': '岡山県',
    '34': '広島県',
    '35': '山口県',
    '36': '徳島県',
    '37': '香川県',
    '38': '愛媛県',
    '39': '高知県',
    '40': '福岡県',
    '41': '佐賀県',
    '42': '長崎県',
    '43': '熊本県',
    '44': '大分県',
    '45': '宮崎県',
    '46': '鹿児島県',
    '47': '沖縄県'
  },

  // ファイルアップロードの許可拡張子
  Extensions: {
    image: ['gif', 'jpg', 'jpeg', 'png', 'tif'],
    document: ['ppt', 'pptx', 'xls', 'xlsx', 'doc', 'docx', 'pdf']
  },
  // APIレスポンス結果
  ApiResult: {
    Success: 'success',
    Failure: 'failure'
  },
  // エラーメッセージ
  Errors: {
    SystemError: {
      title: 'システムエラー',
      body: 'システム管理者にお問い合わせください。'
    }
  }
}
