import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Utility from '@/utils/Utility'
import Vue from 'vue'

const state = {
  userModel: []
}

const getters = {
  get: state => state,
  /**
   * システムアドミンの場合はユーザーリストをアドミン権限に絞り込み
   */
  // eslint-disable-next-line
  getAdminsList: state => userList => {
    return userList.filter(item => {
      let role = Utility.roleToBit(item.UserRole)
      return role.charAt(0) === '1' || role.charAt(1) === '1'
    })
  }
}

const actions = {
  /**
   * ユーザー一覧を読出し
   */
  loadUserList ({getters, commit}, {showAll, role}) {
    return new Promise((resolve, reject) => {
      let parameters = {}
      if (showAll !== undefined) parameters.showAll = showAll
      if (role !== undefined) parameters.roles = role

      ApiManager.request(
        ['user'],
        ['v1UserListGet'],
        [parameters])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            // ユーザーリストをシステムアドミン／テナントアドミンに絞り込み
            commit('setUserModel', getters.getAdminsList(res[0].data.Users))
            console.log('@userModel', state.userModel)
            resolve()

          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {
  setUserModel (state, data) {
    data.sort(Utility.sortByKey('CreatedAt', false))
    Vue.set(state, 'userModel', data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
