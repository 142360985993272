import { mapState } from 'vuex'
export default {
  name: 'Footer',
  computed: {
    ...mapState({
      authorized: state => state.auth.authorized,
      requireChangePassword: state => state.auth.requireChangePassword
    }),
    hideAside () {
      return !this.authorized || this.requireChangePassword
    }
  }
}