import Vue from 'vue'
Vue.config.productionTip = false

// store,router
import App from '@/App.vue'
import { sync } from 'vuex-router-sync'
import router from '@/router'
import store from '@/store/store'
sync(store, router)

// loadScript
import LoadScript from 'vue-plugin-load-script'
Vue.use(LoadScript)
Vue.loadScript('/scripts/polyfill.js?d=' + process.env.VUE_APP_VERSION)
Vue.loadScript('/scripts/axios.min.js')

// vue-table-2
import { ClientTable } from 'vue-tables-2'
Vue.use(ClientTable)

// vue2-google-maps
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCQ3cO28QdOFO742hgMXIYTkerTnzWflzs',
    libraries: 'places'
  }
})

// moment
import moment from 'moment-timezone'
moment.tz.setDefault('Europe/London')

// vue-wisiwig
// import wysiwyg from 'vue-wysiwyg'
// import 'vue-wysiwyg/dist/vueWysiwyg.css'
// Vue.use(wysiwyg, {})

// vue2-dropzone
import vueDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
Vue.use(vueDropzone)

// vue2-admin-lte
import 'va/lib/css'
import 'va/lib/script'

// const
import ApiManager from '@/utils/ApiManager'
window.ApiManager = ApiManager

import Validator from '@/utils/Validator'
window.Validator = Validator

import Constants from '@/utils/Constants'
Constants['_store'] = store
Constants['checkAuth'] = callback => Constants._store.dispatch('auth/checkAuth', callback)
window.Constants = Constants

// css
import '@/assets/css/common.css'


/* eslint-disable no-new */
// // 開発用
// Vue.loadScript('/scripts/libs.dev/apiConfigs.js').then(() => {
// Vue.loadScript('/scripts/libs.dev/apiClientAuthorization.js').then(() => {
// Vue.loadScript('/scripts/libs.dev/apiClientTenants.js').then(() => {
// Vue.loadScript('/scripts/libs.dev/apiClientUsers.js').then(() => {
// Vue.loadScript('/scripts/libs.dev/apiClientAssets.js').then(() => {
// Vue.loadScript('/scripts/libs.dev/apiClientNotices.js').then(() => {
// Vue.loadScript('/scripts/libs.dev/apiClientAws.js').then(() => {
// Vue.loadScript('/scripts/libs.dev/apiClientUtils.js').then(() => {
//   new Vue({
//     router,
//     store,
//     render: h => h(App)
//   }).$mount('#app')
// })
// })
// })
// })
// })
// })
// })
// })

// リリース用
Vue.loadScript(process.env.VUE_APP_API_MANAGER).then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})
