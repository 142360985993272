import { mapState, mapGetters, mapActions } from 'vuex'
import Loading from '@/components/Loading'
import Constants from '@/utils/Constants'

export default {
  name: 'Tenant',
  components: {
    Loading
  },
  metaInfo () {
    this.setMeta({
      title: 'テナント一覧 | ' + Constants.SiteInfo.title
    })
    return this.getMeta
  },
  data () {
    return {
      showLoading: false,
      registerHash: Constants.Params.RegisterHash,
      // テーブルオプション
      tableColumns: [
        'TenantName',
        'TantouName',
        'TantouPhone1',
        'EventCount',
        'LiveFlg'
      ],
      tableData: [],
      tableOptions: {
        headings: {
          TenantName: '名称',
          TantouName: '担当者名',
          TantouPhone1: '担当者電話番号',
          EventCount: 'イベント数',
          LiveFlg: '状態'
        },
        columnsClasses: {
          TenantName: '',
          TantouName: 'wp-25',
          TantouPhone1: 'wp-25',
          EventCount: 'wp-20',
          LiveFlg: 'wp-10'
        },
        sortable: [
          'TenantName',
          'TantouName',
          'EventCount',
          'LiveFlg'
        ],
        filterable: false,
        texts: Constants.TableOptions.getTexts('テナント'),
        perPage: Constants.TableOptions.perPage,
        perPageValues: Constants.TableOptions.perPageValues,
        pagination: Constants.TableOptions.pagination,
        resizableColumns: false,
      },
      // 絞込み
      showAll: 'true'
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapState({
      tenantModel: state => state.tenant.tenantModel
    }),
    ...mapGetters({
      getMeta: 'meta/get'
    }),
    showResult () {
      return !this.showLoading && this.tableData.length > 0
    }
  },
  methods: {
    ...mapActions({
      setMeta: 'meta/set',
      loadTenantList: 'tenant/loadTenantList',
      showError: 'error/show'
    }),
    init () {
      // データ読出し
      this.showLoading = true
      this.loadTenantList({
        showAll: this.showAll === 'true'
      })
        .then(() => {
          this.tableData = $.extend(true, [], this.tenantModel)
          this.showLoading = false
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
        })
    },
    getViewData (props) {
      // console.log(props)
      let key = props.column
      let value = props.row[key]

      if (key === 'TantouPhone1') return value + '-' + props.row.TantouPhone2 + '-' + props.row.TantouPhone3
      if (key === 'LiveFlg') return value ? '有効' : '無効'
      return value
    },
    changePage () {
      // console.log(this.$refs.table.page)
    },
    changeFilters () {
      let parameters = {}
      if (this.showAll !== null) parameters.showAll = this.showAll === 'true'

      // テナント一覧を読出し
      this.showLoading = true
      this.loadTenantList(parameters)
        .then(() => {
          this.tableData = $.extend(true, [], this.tenantModel)
          this.showLoading = false
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
        })
    },
    clickModify (props) {
      console.log('Modify', props)
    }
  }
}