import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Vue from 'vue'

const state = {
  targetId: Constants.Params.RegisterHash,
  values: {}
}

const getters = {
  get: state => state,
  isRegister: state => state.targetId === Constants.Params.RegisterHash,
  isModify: state => state.targetId !== Constants.Params.RegisterHash
}

const actions = {
  /**
   * 初期読出し
   */
  initLoad ({commit}, {targetId}) {
    commit('setTargetId', targetId)

    return new Promise((resolve, reject) => {
      if (getters.isRegister(state)) {
        resolve()

      } else {
        // テナント詳細を読出し
        ApiManager.request(
          ['tenant'],
          ['v1TenantDetailGet'],
          [{ tenantIdentities: [targetId] }])
          .then(res => {
            if (res[0].data.Result === Constants.ApiResult.Success) {
              commit('initLoaded', res[0].data.Tenants[0])
              console.log('@tenantValues', state.values)
              resolve()

            } else {
              reject(res[0].data)
            }
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }
}

const mutations = {
  initLoaded (state, data) {
    Vue.set(state, 'values', {
      name: data.TenantName,
      kana: data.TenantNameKana,
      address: data.TenantAddress,
      addressKana: data.TenantAddressKana,
      division: data.TantouDepartment,
      person: data.TantouName,
      personKana: data.TantouNameKana,
      email: data.TantouEmail,
      phone1: data.TantouPhone1,
      phone2: data.TantouPhone2,
      phone3: data.TantouPhone3,
      contacts: data.TantouAddress,
      invalidFlag: !data.LiveFlg,
      note: data.Note
    })
  },
  setTargetId (state, targetId) {
    state.targetId = targetId
  },
  setValues (state, values) {
    Vue.set(state, 'values', values)
  },
  reset (state) {
    // state.targetId = Constants.Params.RegisterHash
    Vue.set(state, 'values', {})
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
