export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Results: [
        {
          Result: 'success',
          UserIdentity: '36ab6155cb5623d4565ec58f48464c62'
        },
        {
          Result: 'success',
          UserIdentity: 'identifier02'
        },
        {
          Result: 'failure',
          UserIdentity: 'identifier03'
        }
      ]
    }
  }
}