import Vue from 'vue'

const state = {
	model: null
}

const getters = {
  get: state => state
}

const actions = {
	init ({commit}, data) {
    commit('setModel', data)
  },
  reset ({commit}) {
    commit('setModel', null)
  }
}

const mutations = {
	setModel (state, data) {
		Vue.set(state, 'model', data)
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
