// import Constants from '@/utils/Constants'

const state = {
	active: false,
	title: '',
	body: '',
	label: '閉じる'
}

const getters = {
  get: (state) => {
    return state
  }
}

const actions = {
	show ({commit}, {title, body, label}) {
		let parameters = {}
		if (title) parameters.title = title
		if (body) parameters.body = body
		if (label) parameters.label = label
		commit('showed', parameters)
	},
	hide ({commit}) {
		commit('hided')
	}
}

const mutations = {
	showed (state, {title, body, label}) {
		if (title) state.title = title
		if (body) state.body = body
		if (label) state.label = label
		state.active = true
	},
	hided (state) {
		state.active = false
		state.title = ''
		state.body = ''
		state.label = ''
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
