import { mapState, mapGetters, mapActions } from 'vuex'
import Loading from '@/components/Loading'
import Constants from '@/utils/Constants'
import Utility from '@/utils/Utility'

export default {
  name: 'Notice',
  components: {
    Loading
  },
  metaInfo () {
    this.setMeta({
      title: 'お知らせ一覧 | ' + Constants.SiteInfo.title
    })
    return this.getMeta
  },
  data () {
    return {
      showLoading: false,
      registerHash: Constants.Params.RegisterHash,
      // テーブルオプション
      tableColumns: [
        'NoticeSubject',
        'OpenTermStartDt',
        'OpenTermEndDt',
        'UpdatedDt',
        // 'modifyButton'
      ],
      tableData: [],
      tableOptions: {
        headings: {
          NoticeSubject: '題名',
          OpenTermStartDt: '掲載日時',
          OpenTermEndDt: '掲載終了日時',
          UpdatedDt: '変更日時',
          // modifyButton: ''
        },
        columnsClasses: {
          NoticeSubject: '',
          OpenTermStartDt: 'wp-18',
          OpenTermEndDt: 'wp-18',
          UpdatedDt: 'wp-18',
          // modifyButton: 'wp-8'
        },
        sortable: [
          'NoticeSubject',
          'OpenTermStartDt',
          'OpenTermEndDt',
          'UpdatedDt'
        ],
        filterable: false,
        texts: Constants.TableOptions.getTexts('お知らせ'),
        perPage: Constants.TableOptions.perPage,
        perPageValues: Constants.TableOptions.perPageValues,
        pagination: Constants.TableOptions.pagination,
        resizableColumns: false,
      },
      // 絞込み
      showAll: 'true',
      searchQuery: ''
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapState({
      noticeModel: state => state.notice.noticeModel
    }),
    ...mapGetters({
      getMeta: 'meta/get'
    }),
    showResult () {
      return !this.showLoading && this.tableData.length > 0
    }
  },
  methods: {
    ...mapActions({
      setMeta: 'meta/set',
      loadNoticeList: 'notice/loadNoticeList',
      copyData: 'noticeForm/copyData',
      showError: 'error/show'
    }),
    init () {
      // データ読出し
      this.showLoading = true
      this.loadNoticeList({
        showAll: this.showAll === 'true',
        noticeType: 2,
        openterm: 1
      })
        .then(() => {
          this.tableData = $.extend(this.noticeModel, [], true)
          this.showLoading = false
        })
        .catch(error => {
          // API エラー
          if (error.Result === Constants.ApiResult.Failure) {
            this.showError(error)
            
          // システムエラー
          } else {
            this.showError(null)
          }
        })
    },
    getViewData (props) {
      let key = props.column
      let value = props.row[key]

      if (key === 'OpenTermStartDt' ||
        key === 'OpenTermEndDt') {
        if (value) {
          let v = value.split('T').join(' ').split('+09:00').join('')
          return Utility.getDate(v, 'YYYY/MM/DD HH:mm')
        } else {
          return '--'
        }
      }
      if (key === 'UpdatedDt')
        return Utility.getJSTDate(value, 'YYYY/MM/DD HH:mm')
      return value
    },
    changePage () {
      // console.log(this.$refs.table.page)
    },
    changeFilters () {
      let parameters = {
        noticeType: 2,
        openterm: 1
      }
      if (this.showAll !== 'true')
        parameters.status = 1
      if (this.searchQuery)
        parameters.subject = this.searchQuery

      console.log(parameters)

      // お知らせ一覧を読出し
      this.showLoading = true
      this.loadNoticeList(parameters)
        .then(() => {
          this.tableData = $.extend(this.noticeModel, [], true)
          this.showLoading = false
        })
    },
    clickSearch () {
      if (!this.searchQuery) return
      this.changeFilters()
    },
    clickCopy (props) {
      // storeにコピー情報を格納
      console.log('Copy', props)
      this.copyData({
        subject: props.row.NoticeSubject,
        body: props.row.NoticeText
      })

      // ページを遷移
      this.$router.push({
        name: 'NoticeForm',
        params: {
          noticeId: this.registerHash
        }
      })
    }
  }
}