export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      Assets: [
        {
          AssetId: 101,
          TenantIdentity: '36ab6155cb5623d4565ec58f48464c62',
          Name: '岩戸大橋',
          Prefecture: '44',
          City: '竹田市',
          Address: '大分県直入郡荻町大字柏原（竹田市）',
          Latitude: 32.91944444,
          Longitude: 131.29527777,
          Latitude_60_degree: 32,
          Latitude_60_minutes: 55,
          Latidude_60_seconds: 10,
          Longitude_60_degree: 131,
          Longitude_60_minutes: 17,
          Longitude_60_seconds: 43,
          LocationId: '13',
          OwnerName: '大分県竹田土木',
          LineName: '九重野荻線',
          CompletionYear: '2002',
          CompletionMonth: '10',
          Constructor: '施工会社名',
          BridgeAdmin: '橋梁管理者',
          RoadAdmin: '道路管理者',
          OfficeContact: '連絡先',
          OfficeName: '事業所名',
          BridgeForm: '単純鈑桁橋 単純下路式ﾄﾗｽﾄﾞﾗﾝｶﾞｰ橋 単純鈑桁橋',
          Form1: '形式1',
          Form2: 'アーチ橋',
          Form3: '形式3',
          Length: '42/98/32',
          Spanner: '40.8/96.3/30.9',
          Width: '11',
          FallPreventionEq: 2,
          Figure1: '橋梁一般図 S3 Object Key',
          Figure2: '図面2  S3 Object Key',
          Note: '単純鈑桁橋×２連',
          LiveFlg: true,
          CreatedAt: '2019-09-02',
          UpdatedAt: '2019-10-01',
          LastProjectId: 1,
          LatestProjectPhotoCount: 10,
          ProfilePhotoThumbUrl: 'プロファイル写真THUMB URL',
          ProfilePhotoFileObjectKey: 'プロファイル写真S３ OBJECT KEY'
        }
      ]
    }
  }
}
