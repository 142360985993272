export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      User: {
        UserId: 5,
        UserIdentity: '1b2c6a69f00ab0ea7583ce9b3c58b4a5',
        Token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9kZXYuaW5zcGVjdGlvbi5qaXcuY28uanBcL2FwaVwvdjFcL2xvZ2luXC91c2VyIiwiaWF0IjoxNTgzNzMzNjY1LCJleHAiOjE1ODM4MjAwNjUsIm5iZiI6MTU4MzczMzY2NSwianRpIjoiZW51MnliN09pVVpINWZmUyIsInN1YiI6MjcsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.1PnsyFSJKU6l3citm1ZOb2OxsbbP9VTaA8Itc_5O28k',
        TenantId: 10,
        TenantIdentity: '36ab6155cb5623d4565ec58f48464c62',
        TenantName: '株式会社XXXXXXXX',
        Role: 1,
        // 1 : system administrator
        // 2 : tenant administrator
        // 4 : moderator
        // 8 : pilot
        // 16 : inspector
        // 32 : customer
        UserName: 'シスアド 太郎',
        ChangePassword: false,
        TimeStamp: '1583733665',
        CheckStr: 'abadee616fb2af691cc141e8d8d4e31c'
      }
    }
  }
}