import ApiManager from '@/utils/ApiManager'
import Constants from '@/utils/Constants'
import Utility from '@/utils/Utility'
import Vue from 'vue'

const state = {
  noticeModel: []
}

const getters = {
  get: state => state
}

const actions = {
  /**
   * お知らせ一覧を読出し
   */
  loadNoticeList ({commit}, {status, noticeType, openterm, subject}) {
    return new Promise((resolve, reject) => {
      let parameters = {}
      if (status !== undefined) parameters.status = status
      if (noticeType !== undefined) parameters.noticeType = noticeType
      if (openterm !== undefined) parameters.openterm = openterm
      if (subject !== undefined) parameters.subject = subject

      ApiManager.request(
        ['notice'],
        ['v1NoticeListGet'],
        [parameters])
        .then(res => {
          if (res[0].data.Result === Constants.ApiResult.Success) {
            commit('setNoticeModel', res[0].data.Notices)
            console.log('noticeModel', state.noticeModel)
            resolve()

          } else {
            reject(res[0].data)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {
  setNoticeModel (state, data) {
    // UpdatedDtでソート
    data.sort(Utility.sortByKey('OpenTermStartDt', false))
    Vue.set(state, 'noticeModel', data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
