export default {
  mock: {
    config: {},
    headers: {},
    status: 200,
    statusText: '',
    data: {
      Result: 'success',
      Users: [
        {
          TenantId: 1,
          TenantIdentity: '36ab6155cb5623d4565ec58f48464c62',
          TenantName: '株式会社XXXXXXXX',
          UserId: 10,
          UserIdentity: '1b2c6a69f00ab0ea7583ce9b3c58b4a7',
          LoginId: 'tenantadmin01',
          UserRole: 2,
          Name: 'テナント管理 太郎',
          NameKana: 'テナントカンリ タロウ',
          Division: 'プロジェクト推進室',
          Email: 'aaa@aaa.com',
          Phone1: '090',
          Phone2: '3333',
          Phone3: '6666',
          Address: '連絡先住所',
          Note: '備考\n備考',
          LiveFlg: true,
          CreatedAt: '2019-08-02',
          UpdatedAt: '2019-09-20'
        }
      ]
    }
  }
}